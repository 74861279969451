import React, {useState,useEffect} from 'react';
import { 
	BrowserRouter as Router, 
	Route, 
	Switch,
	Redirect,
} from 'react-router-dom'; 
import {NotificationContainer,NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import HomePage from './Components/home/HomePage';
import QuickLinks from './Components/header/QuickLinks';
import NavMenu from './Components/header/NavMenu';
import UserMenu from './Components/header/UserMenu';
import AboutUs from './Components/ourInfo/AboutUs';
import HelpUs from './Components/ourInfo/HelpUs';
import Testimonials from './Components/ourInfo/Testimonials';
import Footer from './Components/footer/Footer';
import Page404 from './Components/ourInfo/page404';
import FAQ from './Components/ourInfo/FAQ';
import ProtectedRoutes from './Components/login/ProtectedRoutes';
import Dashboard from './Components/home/Dashboard';
import firebase,{auth} from './Utils/firebase';
import {viewReducer, appReducer} from './Reducers/reducers';
import {
	HOME,
	HOMEPATH,
	ABOUTPATH,
	HELPUSPATH,
	TESTIMONIALPATH,
	FAQPATH,
	PROTECTEDPATH,
	DASHBOARDPATH,
	DOB,
	DOB_DATA,
	FORM,
	USERS,
	DB_ERROR,
	PRIVACYPOLICY
} from "./Utils/constants.js";
import {getData,setData} from './Utils/firebaseUtils/dbUtils';
import './App.css';
import PrivacyPolicy from './Components/home/PrivacyPolicy';

export const viewContext = React.createContext();
export const appContext = React.createContext();

const userState = {
	view: HOME,
	login: false,
	dob: "",
	fname: "",
	mname: "",
	lname: "",
	sex: "", 
	bar: false,
};

/*const userState = {
	view: DOB_DATA,
	login: false,
	dob: "1998-01-26",
	fname: "Mansi",
	mnane: "",
	lname: "Puggal",
	sex: "female",
	bar: false,
};*/

const appData = {
	dobTraits: '',
	professions: '',
	luckyNumber: '',
	nameCompatibility: '',
	timeTrends: '',
};


function Main() {
	const [uid,setUid] = React.useState(null);
	const [isForm,setIsForm] = useState(false);
	const [number,setNumber] = useState('');
	const {state,dispatch} = React.useContext(viewContext);
    const {appDispatch} = React.useContext(appContext);

	useEffect(()=>{
        if(uid!==null){
            getData(uid,USERS)
            .then(data => {
                if(data!==null){
                    dispatch({
                        type:DOB_DATA,
                        payload:{...data,login:true}
                    });
                }else{
                    if(DOB.test(state.view)){
                        let data = {
                            dob: state.dob,
                            fname: state.fname,
                            mname:state.mname?state.mname:'',
                            lname:state.lname?state.lname:'',
                            sex: state.sex,
                        }
                        setData(uid,USERS,data)
                        .then(() => {
                            dispatch({
                                type:DOB_DATA,
                                payload:{login:true}
                            });
                        }).catch(error => {
							/*dispatch({
                                type:DOB_DATA,
                                payload:{login:true}
                            });*/
							NotificationManager.error("Please check your internet connection or try again later!","USER DATA UNAVAILABLE");
							dispatch({type:DB_ERROR});
                        })
                    }
                    else{
                        setIsForm(true);
                        dispatch({
                            type:FORM,
                        });
                    } 
                }
            })
            .catch(error => {
                NotificationManager.error("Please check your internet connection or try again later!","USER DATA UNAVAILABLE");
				dispatch({type:DB_ERROR});
            });
        }else{
            setIsForm(false);
            dispatch({
                type:'clear',
                payload:{
                    view: HOME,
                    login: false,
                    dob: "",
                    fname: "",
                    mnane: "",
                    lname: "",
                    sex: "", 
                    bar: false,
                }
            })
            appDispatch({
                payload:{
                    dobTraits: '',
                    professions: '',
                    luckyNumber: '',
                    nameCompatibility: '',
                    timeTrends: '',
                }
            })
        }
    },[uid,isForm]);

	auth.onAuthStateChanged(user => {
		if(user){
            if(DOB.test(state.view) && isForm)
                setIsForm(false);
            setUid(user.uid);
			setNumber(user.phoneNumber);
		}
		else{
            setUid(null);
        }
    })

	return (
		<Router>
			<div className="App"> 
				<header id="header">
					<NavMenu/>
					<QuickLinks/>
					<UserMenu number={number}/>
				</header>
				<main id="main">
					<Switch> 	
						<Route path={PROTECTEDPATH} component={ProtectedRoutes}/>
						<Route exact path='/'>
							<Redirect to={HOMEPATH} />
						</Route>
						<Route exact path={HOMEPATH} component={HomePage}/>
						<Route exact path={DASHBOARDPATH} component={Dashboard}/>
						<Route exact path={ABOUTPATH} component={AboutUs}/>
						<Route exact path={HELPUSPATH} component={HelpUs}/>
						<Route exact path={TESTIMONIALPATH} component={Testimonials}/>
						<Route exact path={FAQPATH} component={FAQ}/>
						<Route exact path={PRIVACYPOLICY} component={PrivacyPolicy}/>
						<Route component = {Page404}/>
					</Switch>
					<NotificationContainer/>
				</main>
				<footer id="footer">
					<Footer/>
				</footer>
			</div>
		</Router>
  );
}

function App(){
	//state for different pages in the app, intialised at home page(DOB)
	const [state, dispatch] = React.useReducer(viewReducer, userState);
	const [appState,appDispatch] = React.useReducer(appReducer, appData);

	console.log(process.env.REACT_APP_ENV)
	return(
		<>
			<viewContext.Provider value={{state,dispatch}}>	
				<appContext.Provider value={{appState,appDispatch}}>
					<Main/>
				</appContext.Provider>
			</viewContext.Provider>
		</>
	)
}
export default App;
