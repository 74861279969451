//import {FRIEND,NONFRIEND,NEUTRAL,TEMPFRIEND} from './constants';
const serverURL = "https://asia-east2-ankk-1db9a.cloudfunctions.net/app/";
/*const serverURL = "http://localhost:5000/ankk-1db9a/asia-east2/app/";*/

export const fetchDobTraits = async (data) => {
	let response = await fetch(serverURL+'traits',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});
	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData;
	}
	else{
		throw response.status;
	}
	/*
	let promise = new Promise(function(resolve, reject) {
  		setTimeout(() => resolve({
  			D: 4,
  			C: 2,
  			loshuGrid: [1,3,0,1,0,0,0,1,2],
  		}), 1000);
	});
	let value = await promise;
	return value;*/
}

export const fetchProfessions = async ({dob,sex,D,C,loshuGrid}) =>{
	let data;
	if(D !== undefined && C!== undefined && loshuGrid!== undefined)
		data = {loshuGrid,D,C};
	else
		data = {dob,sex};
	let response = await fetch(serverURL+'professions',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});
	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData;
	}
	else{
		throw response.status;
	}
	/*
	let promise = new Promise(function(resolve, reject) {
  		setTimeout(() => resolve(['data','data','data']), 1000);
	});
	let value = await promise;
	return value;
	*/
}

export const fetchLuckyNumbers = async ({dob,sex,D,C,loshuGrid}) =>{
	let data;
	if(D !== undefined && C!== undefined && loshuGrid!== undefined)
		data = {loshuGrid,D,C};
	else
		data = {dob,sex};
	let response = await fetch(serverURL+'luckynumbers',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});
	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData;
	}
	else{
		throw response.status;
	}
	/*let promise = new Promise(function(resolve, reject) {
  		setTimeout(() => resolve({
  			luckyNumbers:[1,5],
			avoidNumbers:[2,4,8,9],
			luckyColors:['Green and shades of green'],
			avoidColors:['White','Black and Grey','Red and shades of red'],
  		}), 1000);
	});
	let value = await promise;
	return value;
	*/
}

export const fetchNameCompatibility = async ({dob,sex,D,C,loshuGrid,mname,lname,fname}) =>{
	let data;
	if(D !== undefined && C!== undefined && loshuGrid!== undefined)
		data = {loshuGrid,D,C,mname,lname,fname};
	else
		data = {dob,sex,mname,lname,fname};
	let response = await fetch(serverURL+'namecalculator',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});
	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData;
	}
	else{
		throw response.status;
	}
	/*let promise = new Promise(function(resolve, reject) {
  		setTimeout(() => resolve({
			nameNumber:6,
			idealNameNumber:[5],
			firstNameNumber:8,
			nameDComp:NEUTRAL,
			nameCComp:FRIEND,
			firstDComp:NONFRIEND,
			firstCComp:NONFRIEND,
			nameOriginalComp:FRIEND,
		}), 1000);
	});
	let value = await promise;
	return value;
	*/
}

export const fetchTrendBars = async (D) => {
	let data = {D};
	let response = await fetch(serverURL+'trends',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});
	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData;
	}
	else{
		throw response.status;
	}
	
	/*
	let promise = new Promise(function(resolve, reject) {
  		setTimeout(() => resolve([100,50,[100,35],95,100,100,[75,35],85,50]), 1000);
	});
	let value = await promise;
	return value;*/	
}

export const fetchCountryCompatibility = async (D,country) =>{
	let data = {D,country};
	let response = await fetch(serverURL+'country',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});
	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData.countryCompatibility;
	}
	else{
		throw response.status;
	}
	/*let promise = new Promise(function(resolve, reject) {
  		setTimeout(() =>resolve(TEMPFRIEND), 1000);
	});
	let value = await promise;
	return value;*/
}

export const fetchPartnerCompatibility = async (personData,partnerData) => {
	let data = {personData,partnerData};
	let response = await fetch(serverURL+'partner',{
						method:'post',
						headers: {
							'Content-Type': 'application/json',
						},
						body:JSON.stringify(data),
					});

	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData.partnerCompatibility;
	}
	else{
		throw response.status;
	}
	/*let promise = new Promise(function(resolve, reject) {
  		setTimeout(() => resolve({
  			type:FRIEND,
			hasFiveSix:false,
			score:[3.33],
			personLoshuGrid:[1,3,0,1,0,0,0,1,2],
			partnerLoshuGrid:[1,3,0,1,0,0,0,1,4],
  		}), 1000);
	});
	let value = await promise;
	return value;*/	
}

export const fetchNumerology = async (data) => {
	let response = await fetch(serverURL+'numerology',{
		method:'post',
		headers: {
			'Content-Type': 'application/json',
		},
		body:JSON.stringify(data),
	});

	if (response.status >= 200 && response.status <= 299){
		let returnData = await response.json();
		return returnData.numerology;
	}
	else{
		throw response.status;
	}
}