import React from 'react'

function Page404(){
	return(
		<div className='container'>
			<h1 className='tc'>404! We are having trouble finding this page.</h1>
		</div>
	);
}

export default Page404;