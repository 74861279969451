import React from 'react'; 
import Question from '../displaycomponents/Question';
import {questions,answers} from '../../InfoFiles/FAQs'

function FAQ () {
    return(
        <div className='mainContainer'>
            {questions.map((question,index)=> <div className='maincontent mb20'><Question question={question} answer={answers[index]}/></div>)}
        </div>
    )
}

export default FAQ;