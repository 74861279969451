import React from "react";

function PrivacyPolicy () {
    return(
        <div className='mainContainer'>
            <div className=' mb4 maincontent'>
            <h1 className='tc'>Privacy Policy</h1>
            <h2>Personal Information</h2>
            <p>In order to operate the Site in an efficient and effective manner and provide users with information on numerology services & products that may be of interest to them, ankk.co.in may collect personal information, including contact information (such as an email address), from its users. In addition, this information allows us to provide users with relevant Numerological details. We also automatically track certain information based upon your behavior on the Site and use this information to do internal research on our users' demographics, interests, and behavior to better understand, protect and serve users in general.
You agree that ankk.co.in may use your personal information to contact you and deliver information to you that, in some cases, is targeted to your interests or provides administrative notices or communications applicable to your use of the Site. By accepting this Privacy Policy, you expressly agree to receive this information. If you do not wish to receive these communications, we encourage you to opt out of any further receipt by following the opt-out provisions provided in each such communication.
We do not sell our users' personal information to anyone for any reason if the user has indicated a desire for us to keep the information private. When placing an order for a numerology service, our users decide for themselves how much contact information they wish to display.</p>
            <h2>Advertising</h2>
            <p>We use third-party advertising companies to serve ads when you visit our Website. These companies may use information about you and your visits to this and other Websites in order to provide advertisements on this site and other sites about goods and services of interest to you.</p>
            <h2>Cookies</h2>
            <p>We use various technologies to collect and store information when the user browses the website or app, and this may include using cookies or similar technologies to identify their browser or device and also for marketing purposes.</p>
            <h2>Your General Contact Information</h2>
            <p>Managing your privacy is as simple as selecting which pieces of your contact information are displayed. This is done in the section of the resume form called “Contact information”.</p>
            <h2>How we use the information we collect</h2>
            <p>We use the information that we collect for: research and development, marketing, product analysis, and to ensure the best user experience. We also use this information to offer users tailored Numerology content.</p>
            </div>
        </div>
    )
};

export default PrivacyPolicy;