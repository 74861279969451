import React from 'react';
import DobForm from '../displaycomponents/DobForm';
import DobTraits from './DobTraits';
import {DOB_DATA,HOME} from "../../Utils/constants.js";
import { viewContext } from "../../App";

function HomePage() {
	const {state} = React.useContext(viewContext);
	let currentComponent;
	if(state.view === DOB_DATA)
		currentComponent = <div className= 'mainContainer'> <DobTraits/> </div>;
	else if(state.login && state.view===HOME){
		currentComponent = <div className='mainContainer'> </div>
	}
	else{
		currentComponent = <DobForm/>;
	}
	return(		
		<>		
			{currentComponent}
		</>
	);
}

export default HomePage;
