import React from 'react';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import './numerologyReport.css';

function Accordion (props){
    const [isVisible,setIsVisible] = React.useState(props.isVisible===undefined?false:props.isVisible);

    const toggle = () =>{
        setIsVisible(!isVisible);
    }
    return(
        <div className="accordion-container">
            <div className='toggle-container'>
                <div className='accordion-header'>
                    {props.children[0]}
                </div>
                <div className="toggle">
                    {isVisible?<IoIosArrowDropup onClick={toggle} size='20px'/>:<IoIosArrowDropdown onClick={toggle} size='20px'/>}
                </div>
            </div>
            <div>
            {   isVisible?
                <div className='accordion-body'>{props.children[1]}</div>
                :
                null
            }
            </div>
        </div>
    );
}

export default Accordion;