import React from 'react';
import { viewContext } from "../../App";
import DobForm from '../displaycomponents/DobForm';
import ProfessionsInfo from './ProfessionsInfo';
import {DOB, HOME} from "../../Utils/constants.js";

function Professions (){
	const {state} = React.useContext(viewContext);
	let currentComponent;
	if(DOB.test(state.view))
		currentComponent = <div className= 'mainContainer'> <ProfessionsInfo/> </div>;
	else if(state.view===HOME)
		currentComponent = <div className='mainContainer'> </div>
	else
		currentComponent = <DobForm/>; 
	return(
		<>
			{currentComponent}
		</>
	);
}

export default Professions;