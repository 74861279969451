import React from 'react';
import {NotificationManager} from 'react-notifications';
import {FaRegArrowAltCircleLeft} from 'react-icons/fa';
import {fetchPartnerCompatibility} from '../../Utils/apis';
import {viewContext} from '../../App';
import DobForm from '../displaycomponents/DobForm';
import LoshuGrid from '../displaycomponents/LoshuGrid';
import StrengthBar from '../displaycomponents/StrengthBar';
import {peopleCompatibility} from '../../InfoFiles/PartnershipText';
import {NONFRIEND,TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500} from '../../Utils/constants';
import './PartnerCompatibility.css';

function PartnerInfo() {
	const {state,dispatch} = React.useContext(viewContext);
	const [partner,isPartner] = React.useState(false);
	const [personData,setPersonData] = React.useState(state);
	const [partnerData,setPartnerData] = React.useState({});
	const [type,setType] = React.useState('');
	const [hasFiveSix,setHasFiveSix] = React.useState('');
	const [score,setScore] = React.useState('');
	const [scoreType,setScoreType] = React.useState('');
	const [marriage,setMarriage] = React.useState('');

	const onPartnerData = (data) =>{
		setPartnerData({...partnerData,...data});
	}

	const onBack = () =>{
		isPartner(false);
		setPersonData(state);
		setPartnerData({});
		setMarriage('');
		setType('');
		setHasFiveSix('');
		setScoreType('');
		setScore('');
	}

	const onPartnerSubmit = (event) =>{
		event.preventDefault();
		if('dob' in partnerData  &&  'fname' in partnerData && 'sex' in partnerData)
		{
			fetchPartnerCompatibility(personData,partnerData)
			.then(returnData => {
				let personState = {}, partnerState={};
				personState = {...personState,D:returnData.personD,C:returnData.personC,K:returnData.personK,loshuGrid:returnData.personLoshuGrid};
				partnerState = {...partnerState,D:returnData.partnerD,C:returnData.partnerC,K:returnData.partnerK,loshuGrid:returnData.partnerLoshuGrid};
				if(!('mname' in partnerData)){
					partnerState = {...partnerState,mname:''};
				}
				if(!('mname' in personData)){
					personState = {...personState,mname:''};
				}
				if(!('lname' in partnerData)){
					partnerState = {...partnerState,lname:''};
				}
				if(!('lname' in personData)){
					personState = {...personState,lname:''};
				}
				setType(returnData.type);
				setScore(returnData.score);
				let s = Number(returnData.score[0]);
				if(s>=8.00)
					setScoreType('Excellent');
				else if(s>=6.00)
					setScoreType('Good');
				else if(s>=4.00)
					setScoreType('Average');
				else if(s>=2.00)
					setScoreType('Weak');
				else
					setScoreType('Poor');
				setHasFiveSix(returnData.hasFiveSix);
				setPartnerData({...partnerData,...partnerState});
				setPersonData({...personData,...personState});
				isPartner(true);
			})
			.catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:''});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}
		else{
			NotificationManager.warning('Please enter the data');
		}
	}

	const onMarriage = (event) =>{
		event.preventDefault();
		let [y,m,d] = personData.dob.split('-');
		const now = new Date().getFullYear();
		let py = (Number(m)+Number(d)+ now)%9;
		py = py===0?9:py;
		let good = [];
		[1,5,6].forEach(x => {
			if(!((Number(personData.D)===8||Number(personData.C)===8)&&x===1) || ((Number(personData.D)===3||Number(personData.C)===3)&&x===6)) 
				good.push((x-py+9)%9 + now);
		})
		setMarriage(good);
	}

	return(
		<>
			{partner===false?
					<>
						<DobForm partner={true} dataCall={onPartnerData} functionCall={onPartnerSubmit}/>
					</>
					:
					<div className='mainContainer'>
						<FaRegArrowAltCircleLeft onClick={onBack} size='40px'/>
						<div className='loshuinfo'>
							<div className='userinfo maincontent'>
								<span className='nf'>{personData.fname + ' ' + personData.mname + ' ' + personData.lname}</span>
								<LoshuGrid loshuGrid = {personData.loshuGrid} D={personData.D} C={personData.C} K={personData.K}/>
							</div>
							<div className='partnerinfo maincontent'>
								<span className='nf'>{partnerData.fname + ' ' + partnerData.mname + ' ' + partnerData.lname}</span>
								<LoshuGrid loshuGrid = {partnerData.loshuGrid} D={partnerData.D} C={partnerData.C} K={partnerData.K}/>
							</div>
						</div>
						<div className='mb3 maincontent'>
							<p>{peopleCompatibility[type]}</p>
							<p>{type===NONFRIEND?peopleCompatibility['advice']:null}</p>
							<p>{hasFiveSix?null:'In case you are checking compatibility for marriage or love, note that the numbers that provide stability and balance for a happy married life are missing from both your numeroscopes.'}</p>
							<p>You have {scoreType} compatibility!</p>
							{score.length===1?
								<div className='bar'>
									<StrengthBar max={10} value ={score[0]}/>
								</div>
								:
								<>
									<h3>Permanent Partnership: </h3>
									<div className='bar'>
										<StrengthBar max={10} value ={score[0]}/>
									</div>
									<h3>Temporary Partnership: </h3>
									<div className='bar'>
										<StrengthBar max={10} value ={score[1]}/>
									</div>
								</>
							}
							<p className='app-heading'>Note: The compatibility percentage shown here is only indicative and can in no way replace the advice from a personalised reading. For more accurate analysis, contact us.</p>
						</div>
						<div className='maincontent'>
							Looking partnership for marriage? To know which are the best years for you to get married, 
							<span onClick={onMarriage} className='links'>click here</span>.
							{marriage===''?null:
								<>
									<p>In the upcoming nine years following are the best years to get married: {marriage.toString()}</p>
									<p>Note: Avoid marrying during your anti years. To check your anti years check under the life trend section</p>
								</>
							}
						</div>
					</div>
			}
		</>
		
	);
}

export default PartnerInfo;