import React from 'react';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import './Components.css';

function Question ({question,answer}) {
    const [isVisible,setIsVisible] = React.useState(false);

    const toggle = () =>{
        setIsVisible(!isVisible);
    }

    return(
        <>
            <div className='questionContainer'>
                <span className='question app-size app-heading b'>{question}</span>
                <div>
                    {isVisible?<IoIosArrowDropup onClick={toggle} size='40px'/>:<IoIosArrowDropdown onClick={toggle} size='40px'/>}
                </div>
            </div>
            {   isVisible?
                <span  className='app-size'>{answer}</span>
                :
                null
            }
        </>
    );
}

export default Question;