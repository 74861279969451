import React from 'react';
import {NavLink} from 'react-router-dom';
import {
	HOME,
	HOMEPATH,
	NEWPATH,
	PROFESSIONSPATH,
	LUCKYPATH,
	PARTNERPATH,
	NAMEPATH,
	FOREIGNPATH,
	TRENDPATH,
	ABOUTPATH,
	DASHBOARDPATH,
	CELEBPATH,
	REMEDYPATH,
	REMEDY_PAYMENT_PAGE,
	HELPUSPATH,
	TESTIMONIALPATH,
	FAQPATH,
	DEALPEOPLEPATH,
} from '../../Utils/constants';
import './Header.css';



function Navigation({close}){

	return(
		<nav className="NavMenu">
			<NavLink onClick={close} className="Navitems" id='prediction' to={DASHBOARDPATH}>Home</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={HOMEPATH}>About You</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={PROFESSIONSPATH}>Professions</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={LUCKYPATH}>Lucky Numbers</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={PARTNERPATH}>Partner Compatibility</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={NAMEPATH}>Name Analysis</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={FOREIGNPATH}>Foreign Settlement</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={TRENDPATH}>Life Trend</NavLink>
			<NavLink onClick={close} className="Navitems" id='prediction' to={DEALPEOPLEPATH} style={{"textDecoration":"none"}}><div style={{"display":"inline","textDecoration":"underline"}}>Charitra</div><span className="new-item">New</span></NavLink>
			{/*<NavLink onClick={close} className="Navitems" id='prediction' to={CELEBPATH}>Celebrities like you</NavLink>*/}
			<a onClick={close} href={REMEDY_PAYMENT_PAGE} target='_blank'  rel="noopener noreferrer" className="Navitems" id='info'>Remedies</a>
			<hr className="w-90"/>
			<NavLink onClick={close} className="Navitems" id='info' to={ABOUTPATH}>About Us</NavLink>
			<NavLink onClick={close} className="Navitems" id='info' to={FAQPATH}>FAQs</NavLink>
			<NavLink onClick={close} className="Navitems" id='info' to={TESTIMONIALPATH}>Testimonials</NavLink>
			<NavLink onClick={close} className="Navitems" id='info' to={HELPUSPATH}>Help Us Grow</NavLink>	
		</nav>
	);
}

export default Navigation;