import React,{useEffect} from 'react';
import {fetchCountryCompatibility,fetchDobTraits} from '../../Utils/apis';
import {NotificationManager} from 'react-notifications';
import {viewContext,appContext} from '../../App';
import {TEMPFRIEND,TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500} from '../../Utils/constants';
import {loshuBased,dBased,compatibilityBased} from '../../InfoFiles/CountryText';
import CountryList from './CountryList';
import './CountryCompatibility.css';
//import InfoDisplay from '../displaycomponents/InfoDisplay.js';

function CountryInfo() {
	const {state,dispatch} = React.useContext(viewContext);
	const {appState,appDispatch} = React.useContext(appContext);
	const [countryCompatibility,setCompatibility] = React.useState('');
	const [country,setCountry] = React.useState('');

	const onCountrySubmit = (event) => {
		event.preventDefault();
		if(country==='')
			NotificationManager.error('Please select a Country');
		else{
			let d;
			if(appState.dobTraits===''){
				d = Number(state.dob.split['-'][2])%9;
				d = d?d:9;
			}
			else
				d = appState.dobTraits.D;
			fetchCountryCompatibility(d,country)
			.then(returnData =>{
				setCompatibility(returnData);
			}).catch(error => {
				NotificationManager.info(MESSAGE_500,TITLE_500);
			})
		}
	}

	const onCountryChange = (event) => {
		setCountry(event.target.value);
	}

	useEffect(()=>{
		if(appState.dobTraits === ''){
			fetchDobTraits(state)
			.then(returnData => {
				appDispatch({
					type:Object.keys(returnData)[0],
					payload:returnData,
				});
			}).catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:'ERROR'});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}	
	});
	

	const getType = (loshuGrid) =>{
		if(loshuGrid[5-1]===0 && loshuGrid[6-1]===0)
			return 3;
		else if(loshuGrid[5-1]===0 && loshuGrid[6-1]!==0)
			return 0;
		else if(loshuGrid[5-1]!==0 && loshuGrid[6-1]===0)
			return 2;
		else if(loshuGrid[5-1]!==0 && loshuGrid[6-1]!==0)
			return 1;
	}
	
	return(
		<div className="CountryCompatibility">
			<div className="maincontent CompatibilityType">
				{appState.dobTraits===''?null:
					<>
						<p className='app-heading'>{loshuBased[getType(appState.dobTraits.loshuGrid)]}</p>
						{getType(appState.dobTraits.loshuGrid)===3?null:<p>{dBased[appState.dobTraits.D]}</p>}
					</>
				}
			</div>
			<div className="maincontent CountryType">
				<h3 className="CountryHeading">Select a country to test compatibility</h3>
				<span className="CountryInfo">
					<CountryList change={onCountryChange}/>
					<button onClick={onCountrySubmit}>Find Compatibility</button>
				</span>
				{
					countryCompatibility===''?
						null
						:
						<p className='app-heading'>
							{countryCompatibility===TEMPFRIEND?compatibilityBased[countryCompatibility][appState.dobTraits.D]:compatibilityBased[countryCompatibility]}
						</p>
				}
			</div>
		</div>
	);
}

export default CountryInfo;