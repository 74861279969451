import React from 'react';
import {NotificationManager} from 'react-notifications';

const descriptionList = {
	'mobile': 'Enter the mobile number without the country code.',
	'house': 'Enter house number without any block/wing/street number.',
	'car': 'Enter the last four digits of the car.'
}

const resultList = [
	'number is compatible with you!',
	'number is not compatible with you. Try changing it to a friendlier number especially if you use it for professional purposes.',
	' number does not pose any obstacles in your success. It is compatible with you.'
]

function PossesionCompatibility({lucky,avoid}){

	const [description,setDescription] = React.useState('');
	const [pType,setType] = React.useState('');
	const [number,setNumber] = React.useState('');
	const [result,setResult] = React.useState('');

	const onDataChange = (event) => {
		const type = event.target.value;
		setDescription(descriptionList[type]);
		setType(type);
	}

	const onNumberEnter = (event) => {
		const value = Number(event.target.value);
		if(isNaN(value))
			NotificationManager.error('Please enter only numeric values');
		else
			setNumber(value);
	}

	const onFind = (event) => {
		const value = number%9===0?9:number%9;
		if(number==='')
			NotificationManager.error('Please enter a value.');
		else if(lucky.includes(value))
			setResult(resultList[0]);
		else if(avoid.includes(value))
			setResult(resultList[1]);
		else
			setResult(resultList[2]);

	}

	return(
		<div>
			{description===''?null:<span className='i mb2'>{description}</span>}
			<div>
				<label htmlFor=' ma2 type'>Type:</label>
				<select className='ma2' defaultValue={''}  onChange={onDataChange} name='type'>
					<option value = "" disabled>Select</option>
						<option value="mobile">Mobile Number</option>
						<option value="house">House Number</option>
						<option value="car">Car Number</option>
				</select>
				{description===''?null:<input className='ma2' onChange={onNumberEnter} value={number}/>}
				<button className='ma2' onClick={onFind}>Find</button>
			</div>
			{result===''?null:<span>Your {pType} {result}</span>}
		</div>
	)
}

export default PossesionCompatibility;