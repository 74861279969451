import React from 'react';
import PlaneReport from './PlaneReport';
import LoshuReport from './LoshuReport';
import { BsFillCircleFill } from "react-icons/bs";
import {numberTags,karmicTags,masterTags,arrowTags} from '../../../InfoFiles/DealWItthPeople'
import './numerologyReport.css';



function NumerologyReport ({numerology}) {
    let traits = Object.entries(numberTags[numerology.dobData.D]).map(data => data[0]);
    let CTraits = [] , karmicTraits = [] , masterTraits = [];
    
    if(numerology.dobData.C!==numerology.dobData.D)
        CTraits = Object.entries(numberTags[numerology.dobData.C]).map(data => data[0]);
    if(numerology.karmic.isKarmic)
        karmicTraits = karmicTags[Number(numerology.karmic.karmic)].map(data => data);
    if(numerology.master.isMaster)
        masterTraits = masterTags[Number(numerology.master.master)].map(data => data);
    traits = traits.concat(CTraits,karmicTraits,masterTraits);
    numerology.planes.arrow.forEach((data,index) => {
        if(data===1)
            traits = traits.concat(arrowTags[index].map(t => t));
    })
    console.log(traits);
    return(
        <>
            <p className='underline tc'>{numerology.dobData.dob}</p>
            <ul className='traits-container'>
                {traits.map(trait => 
                    <li>
                        <BsFillCircleFill size='10px'/>
                        <div style={{fontSize:'16px',marginLeft:'4px'}}>{trait}</div>
                    </li>)
                }
            </ul>
            <br/>
            <PlaneReport planes={numerology.planes.planes}/>
            <br/>
            <LoshuReport loshuGrid={numerology.loshuGrid}/>
        </>
    );
}

export default NumerologyReport;