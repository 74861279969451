import React from 'react'
import Accordion from './Accordion';
import StrengthBar from '../../displaycomponents/StrengthBar';
import {planeTags} from '../../../InfoFiles/DealWItthPeople'
import './numerologyReport.css';

function Header ({data}){
    const percent = data[1]===2?67:100;
    return(
        <div className='plane-card-header'>
            <span className='plane-name b'>{planeTags[data[0]].name}</span>
            <div className='plane-strength-container'>
                <div className='plane-strengthbar'>
                    <StrengthBar max={100} value={percent}/>
                </div>
                <span>{percent}%</span>
            </div>
        </div>
    );
}

function Body ({data}){
    return(
        <ul>
            {planeTags[data[0]].tags.map(t => <li>{t}</li>)}
        </ul>
    )
}

function PlaneReport ({planes}){
    const sortable = Object.entries(planes)
    .sort(([,a],[,b]) => b-a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    return(
        <div className='plane-card-container'>
        {
            Object.entries(sortable).map(data => data[1]>1?<Accordion key={data[0]}><Header data={data}/><Body data={data}/></Accordion>:null)
        }
        </div>
    );
}

export default PlaneReport;
