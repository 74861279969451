import firebase,{auth} from '../firebase';
import {PHONE} from '../constants';

export const signIn = async (data , type) => {
    if(type===PHONE){
        try{
            const appVerifier= new firebase.auth.RecaptchaVerifier("recaptcha-container",{'size': 'invisible'});
            const confirmResult = await auth.signInWithPhoneNumber(data.number, appVerifier);
            return confirmResult;
        }catch(error) {
            throw error;
        };
    }
}

export const confirmOtp = async (otp,confirmResult) => {
    try{
        const Result  = await confirmResult.confirm(otp)
        return Result;
    }catch(error){
        throw error;
    };
}

export const getCurrentUser = () => auth.currentUser;

export const signOut = async () => {
    try{
        const response = await auth.signOut();
    }catch(error){
        console.log(error);
        throw error;
    }
}