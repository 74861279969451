import React from 'react';
import { viewContext } from "../../App";
import {NotificationManager} from 'react-notifications';
import {DOB_DATA,ERROR,MALE,FEMALE,OTHER} from "../../Utils/constants";
import './Components.css';

function DobForm({partner,dataCall,functionCall}){
	const {state,dispatch} = React.useContext(viewContext);	
	const [data, setData] = React.useState(state);
	const [bday,setDob] = React.useState({
		date:'',
		month:'',
		year:'',
	});

	const onDOBChange = (event) =>{
		if(isNaN(Number(event.target.value))){
			NotificationManager.error('Invalid option');
		}
		else{
			let temp = {
				'date': bday.date,
				'month': bday.month,
				'year': bday.year,
			};
			if(event.target.id==='date' && Number(event.target.value)<10){
				setDob({...bday, [event.target.id]:'0'+Number(event.target.value)});
				temp['date'] = '0'+Number(event.target.value);
			}
			else{
				setDob({...bday, [event.target.id]:event.target.value});
				temp[event.target.id]=event.target.value;
			}
			if(temp.date!=='' && temp.month!=='' && temp.year!==''){
				let d = temp.year + '-' + temp.month + '-' + temp.date;
				setData({...data, 'dob':d});
				if(partner)
					dataCall({'dob':d});
			}
		}
	}

	const onDataChange = (event) => {
		setData({...data, [event.target.id]:event.target.value});
		if(partner)
			dataCall({[event.target.id]:event.target.value});
	};
  
  	const onDOBPredict = (event) =>{
		event.preventDefault(); // button do not submit automatically
		if(partner){
			functionCall(event);
		}
		else{
			if(data.dob !== "" && data.fname !==""  && data.sex !== "" )
			{
				if(isNaN(new Date(data.dob).getTime())){
					NotificationManager.error("Date of Birth entered is not recognized!");
					dispatch({type:ERROR});
				}
				else{
					dispatch({
						type:DOB_DATA,
						payload:data,
					});
				}
			}
			else
			{
				NotificationManager.error("Data not entered");
				dispatch({type:ERROR});
			}
		}
  	}

	return(
		<div className="pa4-l formContainer">
			{partner?null:
				<div className="hc mw6 center br2-ns mt2">
					<h1 className='mainheading mhc'>Welcome to Ankk</h1>
					<h2 className='mainheading'>Numerologically Yours!</h2>
				</div>
			}
  			<form className="fc mw6 center pa4 br2-ns ba b--black-10 mt2">
    				<fieldset className="bn ma0 pa3">
      					<legend className="center s-20">{partner?'Enter your partner\'s information':'Enter the information for free prediction'}</legend>	
      					<div className="flex flex-column items-center">
						<label className="mv1" htmlFor="fname">First Name:</label>
						<input onChange={onDataChange} className="mv1" id="fname"/>
						<label className="mv1" htmlFor="mname">Middle Name:</label>
						<input onChange={onDataChange} className="mv1" id="mname"/>
						<label className="mv1" htmlFor="lname">Last Name:</label>
						<input onChange={onDataChange} className="mv1" id="lname"/>
        				<label className="mv1" htmlFor="dob">Date of Birth:</label>
        				<div className='dob mv1' id='dob'>
							<input id='date' placeholder='Date' onChange={onDOBChange}/>
							<select defaultValue='' id="month" onChange={onDOBChange}>
								<option disabled value=''>Month</option>
	  							<option value='01'>January</option>
	 							<option value='02'>February</option>
	 							<option value='03'>March</option>
	 							<option value='04'>April</option>
	 							<option value='05'>May</option>
	 							<option value='06'>June</option>
	 							<option value='07'>July</option>
	 							<option value='08'>August</option>
	 							<option value='09'>September</option>
	 							<option value='10'>October</option>
	 							<option value='11'>November</option>
	 							<option value='12'>December</option>
							</select>
							<input  id='year'  onChange={onDOBChange} placeholder='Year'/>
						</div>
						<label className="mv1" htmlFor="sex">Sex at birth:</label>
						<select defaultValue={''}  onChange={onDataChange} className="mv1" id="sex" name="sex">
							<option value = "" disabled>Select</option>
  							{/*<option value={OTHER}>Other</option>*/}
 							<option value={FEMALE}>Female</option>
 							<option value={MALE}>Male</option>
						</select>
       						<button onClick={onDOBPredict} className="mv3">
       						{
       							partner?'Find Compatibility':'Submit'
       						}
       						</button>
      					</div>
    				</fieldset>
  			</form>
		</div>
	);
}
	

export default DobForm;