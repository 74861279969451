import React from 'react'
import Accordion from './Accordion';
import StrengthBar from '../../displaycomponents/StrengthBar';
import GaugeChart from 'react-gauge-chart';
import {loshuTags} from '../../../InfoFiles/DealWItthPeople'
import './numerologyReport.css';

const strength =  ['Weak','Average','Strong','Excessive','Extreme'];

function Header ({data,index}){
    return(
        <div className='loshu-card-header'>
            <span className='plane-name b'>{loshuTags[index+1].field}</span>
                <div className='loshu-gauge-chart'>
{/*<StrengthBar max={4} value={(Number(data>4)?4:Number(data))} colorGradient={['orange','#fcf805','lime','#d60202','#590000']}/>*/}
                    <GaugeChart id={index}
                        style = {{
                            height: '200px',
                            width: '200px',
                        }}
                        nrOfLevels={4} 
                        percent={(Number(data>4)?4:Number(data))*0.25}
                        formatTextValue={value => strength[(Number(data>4)?4:Number(data))]}
                        needleColor="#000000"
                        needleBaseColor="#000000"
                        colors={['#fcf805','lime','#d60202','#590000']}/>
                </div>
                {/*<span>{strength[(Number(data>4)?4:Number(data))]}</span>*/}
        </div>
    );
}

function Body ({data,index}){
    return(
        <ul>
            {
                Number(data)>0?
                <div>
                {
                    loshuTags[index+1][(Number(data>5)?'5':data)].map(t => <li>{t}</li>)
                }
                </div>
                :
                <div>
                {
                    loshuTags[index+1].missing.map(t => <li>{t}</li>)
                }
                </div>
            }
        </ul>
    )
}

function LoshuReport ({loshuGrid}){
    return(
        <div className='loshu-card-container'>
        {
            loshuGrid.map((data,index) => <Accordion key={index}><Header data={data} index={index}/><Body data={data} index={index}/></Accordion>)
        }
        </div>
    );
}

export default LoshuReport;
