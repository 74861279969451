import React from 'react';
import {Link} from 'react-router-dom';
import {
    PROFESSIONSPATH, 
    LUCKYPATH, 
    NAMEPATH, 
    TRENDPATH, 
    DEALPEOPLEPATH, 
    PARTNERPATH,
    FOREIGNPATH,
    HOMEPATH,
    REMEDY_PAYMENT_PAGE
} from '../../Utils/constants';
import {viewContext} from '../../App';
import './Dashboard.css';

const services = [
    {
        serviceName: 'Professions',
        description: 'Check the professions that numerology says are most suitable for you and guarantee personal, professional and financial success.',
        path: PROFESSIONSPATH,
    },
    {
        serviceName: 'Lucky Numbers',
        description: 'Check if your mobile number, house number, car number and other numbers in your life are lucky for you or not. Make the most of the numerology of your life path number to access your fortune. ',
        path: LUCKYPATH,
    },
    {
        serviceName: 'Name Analysis',
        description: 'The make or break factor in your life is your name-check if your name numerology is helping you or stopping you from achieving your goals. Also learn how to make it work for you with NO changes on official documents!',
        path: NAMEPATH,
    },
    {
        serviceName: 'Life Trend',
        description: 'Check the energy of the current year, month and day for you to learn how to make the most of numerology to achieve your goals and go with life’s flow.',
        path: TRENDPATH,
    },
    {
        serviceName: 'Charitra',
        description: 'Check the strengths and weaknesses of anybody around you-your partner, your children, your employees-all in a single place. This is the numerology key that will help you learn how to deal with people around you.',
        path: DEALPEOPLEPATH,
    },
    {
        serviceName: 'Partner Compatibility',
        description: 'Romantic partners, spouses, business partners, and friends-check what numerology has to say about your compatibility and the nature of your relationship.',
        path: PARTNERPATH,
    },
    {
        serviceName: 'Foreign Settlement',
        description: 'Here you can check whether settling in countries abroad suits you or not and if it does, what are the countries that provide the best opportunities for personal and professional success.',
        path: FOREIGNPATH,
    },
    {
        serviceName: 'About you',
        description: 'Simply input your date of birth and name to receive a detailed personality analysis covering your strengths and weaknesses, as well as numerology advice to make the most of your potential.',
        path: HOMEPATH,
    },
];

function Dashboard () {
    const {state} = React.useContext(viewContext);

    return(
        <div className='dashboard-container'>
            <h1 className='dashboard-header-text'>Namaste {state.fname===''?'User':state.fname} !</h1>
            <div className='service-container'>
            {
                services.map(service => {
                    return(
                        <Link to={service.path} className='service-card'>
                            <h2 className='underline'>{service.serviceName}</h2>
                            <p>{service.description}</p>
                        </Link>
                    )
                })
            }
                <a href={REMEDY_PAYMENT_PAGE} target='_blank'  rel="noopener noreferrer" className='service-card'>
                    <h2 className='underline'>Remedies</h2>
                    <p>Here you can get practical and inexpensive solutions to life’s problems-elements, yantras, rituals, practical advice-all the tips and tricks that can help you turn your life around.</p>
                </a> 
            </div>
        </div>
    );
};

export default Dashboard;