const YearText = ['This is not a particularly good year for you. The driving force of the year is in conflict with the driving force of your life.This conflict may manifest as mental and physical health problems, losses in business or possibilities of accidents. It is extremely important to take care of yourself this year-prioritise your physical and mental health over anything else. For specific remedies that can help alleviate the suffering,',/*anti year*/
	'This year is a year of new beginnings. You will have immense energy and enthusiasm to escalate and accomplish your goals and dreams, whether they are on the career front or in your personal life. It is a year to grab new opportunities and see them to fruition. Any new venture that you begin this year is likely to have a great impact on your success. Make the most of the potential of this year!',/*1*/
	'This is a slow and gentle year, one of consolidation of plans that began last year. It is advised that you patiently wait for things to be where they need to be. There will be a tendency to push things using force but such efforts are likely to be futile. Instead of wasting your energy in making new things happen, focus your resources towards analysing, planning and organising things. The time for implementation will come and you will slowly and steadily reach the place where you want to be by the end of the year',/*2*/
	'This is the year of educational possibilities for you. This is the perfect year to learn something new, to explore spirituality, religion, Occult Sciences and other sources of knowledge. If you are faced with a situation where you have to choose between education and fun, our advice is that you make the most of the potential of education because fantastic results are guaranteed in these fields. On the work front, try and keep everything stable.',/*3*/
	'This is a Karmic year for you-you will receive in direct proportion to your hard work. Begin the year by setting realistic yet challenging goals for your life, charge at them with a focussed approach and it is guaranteed that you will achieve the goals by the end of the year. Naturally, the converse of this is also true, if you do not invest in your goals with your time and energy, you are unlikely to benefit from this year. ',/*4*/
	'This year is a year of personal success and unexpected gains. It is a year of receiving and to make the most of this take risk, plan and convert your plans to 100% results. It is the perfect year for any sort of change and variety-to change your country, change your job, for a promotion, to get married, to have children, for instance.',/*5*/
	'This is a year of luxury and a year of family and relationships. It is a perfect year to make any decisions regarding the family like getting married, having children,buying a new car, shifting to a new country, buying a new home, redecorating your existing home and so on. Similarly, on the work front, it is the year to push for your promotion, start a new business, expand your current business and the like.',/*6*/
	'This is the year of wisdom and spirituality. It is a great year for any kind of formal or informal education, for learning Occult Sciences, for research, for taking a religious tour, a sabbatical from work, or investing in meditation practices. On the work front, this may not be a prosperous year-it is good for planning new things and analysing existing programmes but not good for implementing new ventures.',/*7*/
	'This year demands a lot of work but brings financial prosperity and monetary gains for you. It is a great year to claim any money or property that has been disputed, and buy or sell any property that you may wish to. You receive in direct proportion to your hard work and any goal that is approached with focussed energy will definitely be realised this year. This tendency, however, can put you in a workaholic zone and that is why it is advised that you take out time to relax, rest and unwind every now and then lest it affect your health.',/*8*/
	'This year is an audit period, a re-evaluation period of sorts. It is the end of the epicycle and gives you results on the basis of what you have done in the past 8 years. It is also a Trap Period because you may be misguided by a lot of false leads to make major changes. Try and not fall for these and be stable where you are. Shelve new ideas for the moment or delay their implementation to next year. The confusion that characterises this period will slowly settle by the last quarter of the year and you will find a ray of hope soon enough.',/*9*/
	'',//default
]

export const MonthText = [
	'This is not a particularly good month for you. The driving force of the month is in conflict with the driving force of your life.This conflict may manifest as mental and physical health problems, losses in business or possibilities of accidents. It is extremely important to take care of yourself this month-prioritise your physical and mental health over anything else. For specific remedies that can help alleviate the suffering,',/*anti*/
	'This month is great for new beginnings. You have immense energy and enthusiasm to escalate and accomplish your goals and dreams, whether they are on the career front or in your personal life. It is a month to grab new opportunities and see them to fruition. New and creative ideas will flow to you in multitudes! Any new venture that you begin this month is likely to have a great impact on your success. Make the most of the potential of this month!',/*1*/
	'This is a slow and gentle month, one to complete activities that you began last month. It is advised that you patiently wait for things to be where they need to be. There will be a tendency to push things using force but such efforts are likely to be futile. Instead of wasting your energy in making new things happen, focus your resources towards analysing, planning and organising things. The time for implementation will come and you will slowly and steadily reach the place where you want to be by the end of the month. Allow the flow of the month to take you forward gently-relax and slow down this month. Your intuitive powers are going to be very strong in this period so pay attention to your inner voice.',/*2*/
	'This is the month of educational possibilities for you. This is the perfect month to learn something new, to explore spirituality, religion, Occult Sciences and other sources of knowledge. If you are faced with a situation where you have to choose between education and fun, our advice is that you make the most of the potential of education because fantastic results are guaranteed in these fields.So make the most of it and look up new skills that you have always wanted to learn but couldn’t. Find online courses that can prove productive for you and invest in your learning!',/*3*/
	'This is a Karmic month for you-you will receive in direct proportion to your hard work. Begin the month by setting realistic yet challenging goals for your life, charge at them with a focussed approach and it is guaranteed that you will achieve significant results. Naturally, the converse of this is also true, if you do not invest in your goals with your time and energy, you are unlikely to benefit from the potential of this month. The tendency here is to indulge in a lot of physical work, whether it is profession-related or personally like exercising and workout. In case you are not able to physicalise this tendency, restlessness and anxiety are likely to follow.',/*4*/
	'While this month may make you feel lazy or laid-back, it is a month of personal success and unexpected gains. It is a month of receiving and to make the most of this, take risk, plan and convert your plans to 100% results. It is the perfect month for any sort of change and variety-to change your country, change your job, for a promotion, to get married, to have children, for instance. Your mind will be flooded with creative ideas this month.',/*5*/
	'This is a month of luxury and a month of family and relationships. It is a perfect month to make any decisions regarding the family like getting married, having children, buying a new car, shifting to a new country, buying a new home, redecorating your existing home and so on. Similarly, on the work front, it is the month to push for your promotion, start a new business, expand your current business and the like. Overall, this will be a lavish month-material wealth is incoming! Enjoy the comfort and luxury that this month offers!',/*6*/
	'This is the month of wisdom and spirituality. It is a great month for any kind of formal or informal education, for learning Occult Sciences, for research, for taking a sabbatical from work, or investing in meditation practices. It is possible that the wisdom that this month offers will come through certain difficult situations. Be patient and focus on what the situation is teaching you so that you can move on from it. Overall, you may take a keen interest in reading books, religion or spirituality. Deep philosophy will intrigue you-whether through art and music or science. ',/*7*/
	'This month demands a lot of work but brings financial prosperity and monetary gains for you. It is a great month to claim any money or property that has been disputed, and buy or sell any property that you may wish to. You receive in direct proportion to your hard work and any goal that is approached with focussed energy will definitely be realised this month. This tendency, however, can put you in a workaholic zone and that is why it is advised that you take out time to relax, rest and unwind every now and then lest it affect your health. In case there is no opportunity of work, or you choose to not do work, there is a tendency to feel restless or anxious.',/*8*/
	'This month brings confusion and existential questions. You will be tempted to take major decisions or grab opportunities that look inviting but it is suggested that you postpone such decisions to the next month. For this one, try and stay as stagnant and stable as you can. Don’t be too upset about the confusing thoughts you face, you will gain some clarity by the end of the month. There is light at the end of the tunnel!',/*9*/
	'',/*10 default*/
	'This particular month is one where the above-mentioned potential and tendency of the year is at its peak. So keeping the trend of the year in mind, follow through with actions that help you make the most of it!',/*py==pm*/
]

export const DayText = [
	'This is a day to proceed with extreme care and caution. The energy of the day is not necessarily in your favour and as such, it is best to focus on activities that benefit your mental and physical health-meditation, eating nutritious meals, and taking proper rest. It is a good day to do the remedies that have been suggested for you.',/*anti*/
	'You will have a lot of energy and enthusiasm today! Make the most of the potential of this day to begin new tasks, whether in your professional or personal life. If you have a project you want to kickstart, today is the day to do it. If you want to go ahead with building a new personal relation, today is the day to initiate it. Whatever you begin today will greatly impact your overall success!',/*1*/
	'Today is going to be slightly slow and gentle. Try and be patient in everything that you do. It is a good day to sit back and organise, plan or analyse things as they are. Don’t try to push things to be as you want them to be. Mood swings are likely. Be calm with yourself and others and tomorrow will provide a better opportunity to do all that you wish to.',/*2*/
	'Today is a good day to learn something new. The energy is in your favour to get educational tasks done like finishing off assignments, reading new academic literature, starting a new project and so on. It is also a good day to visit the temple, do Yoga or meditate and connect with the Universal Source of all Energy.',/*3*/
	'Today is a good day to get busy and work towards your goals with a hawk-like focus because you will get results in direct proportion to your hard-work. It is a good day to complete pending tasks and take up new ones. Be careful of getting too busy with work and ensure that you eat your meals on time, keep yourself hydrated and take frequent breaks to recharge yourself. The energy of the day is such that if you are unable to physicalise it through any sort of labour, it may make you anxious or restless.',/*4*/
	'Today is a day of unexpected gains in both professional and personal life. The energy of the day is one of laziness and relaxation, so be easy on yourself. Ideas may flow in abundance so make the most of them. It is a good day to take risks and implement plans that were put on hold.',/*5*/
	'Today is a good day to spend with your friends, family and loved ones. Quality time with a romantic partner will add more value to your relationship today. It is a day to enjoy luxury and comfort as well. If you want someone to give you something in particular, this is the day to ask for it. Any sort of income is also likely to be received today.',/*6*/
	'Today is a good day to learn something new. The energy is in your favour when it comes to educational tasks like finishing off assignments, reading new academic literature, starting a new project and so on. It is also a day of wisdom so you may feel the urge to read deep philosophy or listen to meaningful music. It is also a good day to visit the temple, do Yoga or meditate and connect with the Universal Source of all Energy. ',/*7*/
	'This is the day to settle any financial issues or payments that have been stuck. It is a good day to invest, or make profitable deals. The day brings a lot of work energy and as such, you may be kept on your feet all day long or wish to accomplish physical tasks like a workout. In case you fail to channelise this excess energy as mentioned above, restlessness may follow.',/*8*/
	'Today may be a confusing day. It is the end of the epicycle, and that brings a lot of restlessness. You will want to make major changes and start new things, but it is suggested that you postpone them for tomorrow. Try and be patient and relax today. Allow the flood of thoughts to come and go, don’t dwell on any for too much time lest they make you anxious.',/*9*/
	'',/*default*/
]

export const antiCombo = ['1,8','2,8','3,6','4,2','6,3','8,1','8,2']; // PY-D

export default YearText;