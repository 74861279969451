import React, { useEffect } from 'react';
import { viewContext } from "../../App";
import {Route, Switch} from 'react-router-dom';
import CountryCompatibility from '../countrycompatibility/CountryCompatibility';
import DealWithPeople from '../dealpeople/DealWithPeople';
import NameCompatibility from '../namecompatibility/NameCompatibility';
import PartnerCompatibility from '../partnership/PartnerCompatibility';
import LuckyNumbers from '../personalcompatibility/LuckyNumbers';
import Professions from '../professions/Professions';
import YearTrends from '../yeartrends/YearTrends';
import Login from './Login';
import {
    PROFESSIONSPATH, 
    LUCKYPATH, 
    NAMEPATH, 
    TRENDPATH, 
    DEALPEOPLEPATH, 
    PARTNERPATH,
    FOREIGNPATH,
    LOGINPATH,
} from '../../Utils/constants';
import Dashboard from '../home/Dashboard';

function ProtectedRoutes(props){
    
    const {state} = React.useContext(viewContext);
                    
    return(
        <>
            {state.login?
                <Switch> 	
                    <Route exact path={PROFESSIONSPATH} component={Professions}/>
                    <Route exact path={LUCKYPATH} component={LuckyNumbers}/>
                    <Route exact path={NAMEPATH} component={NameCompatibility}/>
                    <Route exact path={PARTNERPATH} component={PartnerCompatibility}/>
                    <Route exact path={FOREIGNPATH} component={CountryCompatibility}/>
                    <Route exact path={TRENDPATH} component={YearTrends}/>
                    <Route exact path={DEALPEOPLEPATH} component={DealWithPeople}/>
                    <Route exact path={LOGINPATH} component={Dashboard}/>
                </Switch>
                :
                <Login/>
            }
        </>
    )
};

export default ProtectedRoutes;