import React,{useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {fetchLuckyNumbers} from '../../Utils/apis';
import {viewContext,appContext} from '../../App';
import PossesionCompatibility from './PossesionCompatibility';
import {TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500} from '../../Utils/constants';
import './LuckyNumbers.css';


function ColorCards({colors}){
	return colors.map(color => <span card={color} className="ColorCard" >{color}</span>);
}

function PersonalInfo() {
	const {state,dispatch} = React.useContext(viewContext);
	const {appState,appDispatch} = React.useContext(appContext);

	useEffect(()=>{
		if(appState.luckyNumber===''){
			fetchLuckyNumbers(state)
			.then(returnData => {
				appDispatch({
					type:Object.keys(returnData)[0],
					payload:returnData,
				});
			}).catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:'ERROR'});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}
	},[]);

	return(
		<div className='LuckyContainer'>
			<div className='maincontent Numbers'>
				<span>
					<span className='app-heading'>Lucky Numbers: </span>
					<span>{appState.luckyNumber===''?null:appState.luckyNumber.luckyNumbers.toString()}</span>
				</span>
				<span>
					<span className='app-heading'>Numbers to avoid: </span>
					<span>{appState.luckyNumber===''?null:appState.luckyNumber.avoidNumbers.toString()}</span>
				</span>
				<span>These are numbers to keep in mind when purchasing a car, a home, a new mobile number, name number for business and so on.</span>
				<div>
					<h3 className='app-heading'>Find the compatibility of your personal things</h3>
					<PossesionCompatibility lucky={appState.luckyNumber.luckyNumbers} avoid={appState.luckyNumber.avoidNumbers}/>
				</div>
			</div>
			<div className='maincontent Colors'>
				<span>
					<span className='app-heading'>Lucky Colors: </span>
					<span className="CardContainer">
						{appState.luckyNumber===''?null:<ColorCards colors={appState.luckyNumber.luckyColors}/>}
					</span>
				</span>
				<span>
					<span className='app-heading'>Colors to avoid: </span>
					<span className="CardContainer">
						{appState.luckyNumber===''?null:<ColorCards colors={appState.luckyNumber.avoidColors}/>}
					</span>
				</span>
				<span>Completely avoiding a certain colour all your life seems like an impossible task and so the best way to use information on colours is to keep this in mind for important days like for interviews, presentations, performances and the like. At other times, use them in any capacity you feel comfortable- while deciding the colour of your car, the colour of your walls, the colour of your clothes and so on.</span>
			</div>
		</div>
	);
}

export default PersonalInfo;


