import React from 'react';
import NumerologyReport from './numerologyReport/NumerologyReport';
import {NotificationManager} from 'react-notifications';
import {MALE,FEMALE,TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500} from '../../Utils/constants';
import {fetchNumerology} from '../../Utils/apis';
import './DealWithPeople.css';

function DealWithPeople () {
    const [dob,setDob] = React.useState({date:'dd',month:'mm',year:'yyyy',displayText:''});
    const [sex,setSex] = React.useState('');
    /*const [numerology,setNumerology] = React.useState({
        dobData: {
            C: 4,
            D: 2,
            dob: "1998-02-02",
            date: "02",
            month: "02",
            year: "1998"
        },
        karmic:{
            isKarmic: true,
            karmic: "10"
        },
        loshuGrid: [3,3,0,1,0,6,0,1,2],
        master:{
            isMaster: true,
            master: '22',
        },
        planes:{
            arrow: [1,1,1,1],
            planes:{
               'ACTION': 1,
               'EMOTIONAL': 0,
               'MENTAL': 3,
               'PRACTICAL': 2,
               'RAJYOG-I': 1,
               'RAJYOG-II': 2,
               'THOUGHT': 2,
               'WILL': 2 
            }
        }
    });*/
    const [numerology,setNumerology] = React.useState(undefined);

    const onDOBChange = (event) => {
        const prevInput = dob.displayText;
        let [date,month,year] = event.target.value.split('/');
        if((date!==undefined && isNaN(Number(date))) || (month!==undefined && isNaN(Number(month))) || (year!==undefined && isNaN(Number(year)))){
            setDob({...dob,displayText:prevInput});
        }
        else{
            const d = Number(date);
            const m = Number(month);
            const y = Number(year);
            if(d>31){
                const newD = Math.floor(d/10);
                const remainderD = d%10;
                date = (newD<10?'0':'') + newD.toString();
                if(isNaN(m)){
                    if(remainderD>1)
                        month = '0' + remainderD.toString();
                    else
                        month = remainderD.toString();
                }
            }
            else if(m>12){
                const newM = Math.floor(m/10);
                const remainderM = m%10;
                month = (newM<10?'0':'') + newM.toString();
                if(isNaN(y)){
                    year = remainderM.toString();
                }
            }
            else if(y>9999){
                year = Math.floor(y/10).toString();
            }
            else{
                if(y>=0){
                    if(y==0)
                        year='';
                    else
                        year = y.toString();
                }
                if (d>10)
                    date = d.toString();
                if(m>10)
                    month = m.toString();
            }
            month = month===undefined?'mm':month;
            year = year===undefined?'yyyy':year;
            setDob({
                date: date,
                month: month,
                year: year,
                displayText: date + (date.length===2 || (month.length===2 && month!=='mm') || year!=='yyyy'?'/':'')+ (month==='mm'?'':(month)) + ((month.length===2 && month!=='mm')|| year!=='yyyy'?'/':'') + (year==='yyyy'?'':(year))
            });
        }
    }

    const onDataSubmit = (event) =>{
        event.preventDefault();
        const dobFormat = [dob.year,dob.month,dob.date].join('-');
        const date = new Date(dobFormat);
        if( isNaN(date.getTime()) || Number(dob.date)!==date.getDate() ||  Number(dob.month)!==date.getMonth()+1 || Number(dob.year)!==date.getFullYear()){
            NotificationManager.error('Invalid date!');
        }
        else if(sex===''){
            NotificationManager.error('Please select the sex to proceed further!');
        }
        else{
            fetchNumerology({dob:dobFormat,sex:sex})
            .then(returnData =>{
                setNumerology(returnData)
                setDob({date:'dd',month:'mm',year:'yyyy',displayText:''});
            })
            .catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
        }
    }

    return(
        <div className='mainContainer'>
            <div className='maincontent mb20'>
                <h1 className='underline app-heading tc'>Charitra</h1>
                <h2 className='underline app-heading tc'>Understand and Deal With People</h2>
                <p className='tc'>Insert the Date of Birth and sex of the person you want to understand better:</p>
                <div className='dob-input-box'>
                    <input id='date' placeholder='DD/MM/YYYY' value={dob.displayText} onChange={onDOBChange}/>          
                    <select defaultValue='' onChange={(event)=>setSex(event.target.value)}>
                        <option disabled value=''>Sex</option>
                        <option value={FEMALE}>Female</option>
                        <option value={MALE}>Male</option>
                    </select>
                    <button className="app-button" onClick={onDataSubmit}>Find</button>
                </div>
            </div>
            {numerology===undefined?null:
                <div className='maincontent'>
                    <NumerologyReport numerology={numerology}/>
                </div>
            }
        </div>
    )
}

export default DealWithPeople