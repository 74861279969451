import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import {db} from '../../Utils/firebase';
import {signIn,confirmOtp} from '../../Utils/firebaseUtils/loginUtils';
import {PHONE} from '../../Utils/constants';
import {NotificationManager} from 'react-notifications';
import 'react-phone-input-2/lib/style.css'
import './Login.css';

function Login () {
    const [number,setNumber] = useState('');
    const [otp,setOtp] = useState('');
    const [otpHandler,setOtpHandler] = useState(false);
    const [confirmationResult, setConfirmationResut] = useState();
    const [count,upCount] = useState(0);

    
    const onSignIn = (event) => {
        event.preventDefault();
        signIn({number:'+'+number},PHONE)
        .then(confirmResult => {
            setOtpHandler(true);
            setConfirmationResut(confirmResult);
        })
        .catch(error => {
            if(error.code==="auth/invalid-phone-number"){
                NotificationManager.error("Please check the phone number.","INVALID PHONE NUMBER");
            }
            else{
                NotificationManager.error("There seems to be some network issue, please check your internet or try again after sometime.");
            }
            setTimeout(()=> window.location.reload(false),2000);
        });
    }

    const handleOtp = () => {
        confirmOtp(otp,confirmationResult)
        .then((result) => {
            const user = result.user;
            //add the user data if we have it
          }).catch((error) => {
              if(count<2){
                NotificationManager.error(`The OTP provided is incorrect. ${2-count} attempts left.`,"INVALID_VERIFICATION");
                upCount(count+1);
              }
              else{
                window.location.reload(false);
              }
          });
    } 

    return(
        <div className='login-page'>
            {
                otpHandler?
                <>
                    <h2 className="login-heading">Enter the OTP sent to your number.</h2>
                    <div className="otp-container">
                        <input onChange={e => setOtp(e.target.value)} placeholder="OTP" className="otp-text"/>
                        <button onClick={handleOtp} className='otp-button'>Confirm</button>
                    </div>
                </>
                :
                <>
                    <h2 className="login-heading">To continue, log in to Ankk.</h2>
                    <div>
                        <ReactPhoneInput
                            inputExtraProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true
                            }}
                            country={'in'}
                            dropdownStyle={{color:'black'}}
                            value={number}
                            onChange={phone => setNumber(phone)}
                        />
                    </div>
                    <button className='login-button' onClick={onSignIn}>Log In</button>
                    <div id='recaptcha-container'></div>
                </>
            }
        </div>
    );
}

export default Login;