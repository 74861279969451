import React from 'react';
import {DONATION_LINK,SURVEY_LINK} from '../../Utils/constants';

function HelpUs(){
	return(
		<div className='mainContainer'>
			<div className='maincontent'>
			 	<h2 className='underline app-heading'>You Can Help Us Grow!</h2>
			 	<p>Hello there! We hope you found some value in all the information that was provided for you. If you found it worth a little something, do support us so that we can continue providing this information to our users for free! <span className='app-heading'>If you wish to make a contribution, <a href={DONATION_LINK} target='_blank' rel="noopener noreferrer" className='links'>click here</a></span></p>
			 	<p>If monetary contribution is not possible at the moment, help us make the user experience better by <span className='app-heading'>filling this <a href={SURVEY_LINK} target='_blank' rel="noopener noreferrer" className='links'>survey form</a></span> and share our website on social media or among your friends can also help us grow.</p>
			 	<p>
			 	Thank you!<br/>
			 	Numerologically Yours,<br/>
			 	Ankk
			 	</p>
			</div>
		</div>
	)
}

export default HelpUs;