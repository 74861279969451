export const numberTags = [
    '',/*0*/
    {
        'Ambitious': [],
        'Leader': [],
        'Authoritative': ['Prefers being the Boss than being bossed','High ego'],
        'Powerful': [],
        'Rises through the Ranks': [],
        'Royal': [],
    },/*1*/
    {
        'Thinks Fast': [],
        'Beautiful': ['Attractive','Charming'],
        'Sensitive': ['Avoids hurting others','Avoids getting hurt','May snap when abused'],
        'Supportive': ['Loves supporting others','Loves getting support'],
        'Fickle-Minded':[],
        'Feminine Character': ['Well-mannered','Great with other feminine personalities'],
        'Great Host': ['Great story-teller','Speaks pleasant words'],
    },/*2*/
    {
        'Knowledgeable': ['Prefers knowledge or learning over money','Does great in the Education Sector'],
        'Creative': [],
        'Imaginative': [],
        'Great Teacher or Preacher': [],
        'Golden at Heart': [],
        'Studious': [],
        'Saatvik or Holy':[],
    },/*3*/
    {
        'Disciplined and Organised': ['Great at managing chaos','Strict or stubborn when in authority'],
        'Rebellious': [],
        'Logical And Scientific': ['Will ask a lot of questions','Will not accept things without Logic or Science'],
        'Law and Marketing is Ideal': [],
        'Secretive': ['Will keep others’ secrets well','Nor will be open about own secrets'],
        'Argumentative': ['Gets caught up in a debate or an argument','Prone to fights or cold wars','Gets angry easily and may get abusive as well'],
    },/*4*/
    {
        'Self-Accountable': [],
        'Self-Responsible': ['Does not like interference','Cannot take pressure','Keeps resignation in pocket'],
        'Lazy': ['Prefers smart work to hard work','Prefers mind work to leg work','Prefers speed in work to monotony'],
        'High Business Acumen': [],
        'Happy-Go-Lucky': ['Always young at heart'],
        'Balanced': ['Emotionally, Mentally, Physically','Financially','Relationship wise','Tries to bring balance in all spheres of life'],
        'Romantic': [],
    },/*5*/
    {
        'Romantic': ['High sexual energy','Great lover'],
        'Love Luxury, Glamour, Tour and Travel': ['Make-up','Make-up'],
        'Street Smart': [],
        'Diplomatic and Manipulative': [],
        'Jugaadu': [],
        'Can take someone’s case without them realising it': [],
        'avoid professions like teaching and preaching':[],
        'Knows the correct way and occasion to lie effortlessly':[],
    },/*6*/
    {
        'Wise': ['Learns from their mistakes','Learns from the disappointments in life'],
        'Golden Heart': [],
        'Gullible': ['Follows others','Adjusts easily'],
        'Defined by their Conductor or Name': ['Therefore a lot of exceptions are found','Other numbers can define their personality','Other people can define their behaviour'],
        'Avoid fighting, shouting or getting in someone’s bad books': [],
        'Spiritual or Religious': [],
        'Perfect for Occult Science':[],
        'Great at Solving Intricate Problems':['Great detectives','Great at solving puzzles'],
        'Logical':[],
        'Scientific':['Great at Research like Ph.D or higher studies'],
        'Intuitive':['Can be great at sports','Can be impulsive'],
        'Quite Insecure':[],
        'Highly attracted to other people':['May find solace in friends due to disappointments'],
    },/*7*/
    {
        'Most Hardworking & Laborious': ['Affinity to work is high','Higher Energy','Focused and Attentive'],
        'Royal': [],
        'Egoistic': ['They are the best','They think they are the best'],
        'Hustlers': ['Struggles more to achieve what they deserve','Sets higher bars in whichever field they go'],
        'Hard Nut to Crack': ['Difficult to please','Difficult to convince','Difficult to fool','Difficult to argue with'],
        'Like a Tortoise': ['Moves slowly but consistently','Prefers being Cautious and Slow rather than Fast and Foolish'],
        'Strict and Stubborn': ['Follows Rule','Loves making rules'],
        'Great in Money and Finance Management': [],
        'Ideal for Law and Judiciary': [],
        'Delay': ['In success and love but no denial','First half of life/a project may involve struggle but the second-half compensates for it (or vice versa)'],
    },/*8*/
    {
        'Warriors': ['Dabangg','Strong','Brave Heart','Best in Daring Professions-Army/Police'],
        'Moody & Unpredictable': ['When friendly, will be super-supportive','When unfriendly, is the worst enemy to have','Can swing from loving too much to being too furious '],
        'Humanitarian': ['Loves social service','Loves charity'],
        'Low inclination towards sex or sexual activities': [],
        'Exaggeration': ['Loves connecting dots','Emotions reach from 0 to 100 in no time '],
    },/*9*/
]

export const karmicTags = {
    '10': ['Should focus on doing good actions'],
    '13': ['Power and Authority'],
    '14': ['Enjoys freedom'],
    '16': ['Gets love from people around'],
    '19': ['Enjoys Power'],
}

export const masterTags = {
    '11': ['Dreamer, not doer','Needs social support to approach dreams realistically'],
    '22': ['Dreamer and Doer','Action and Visualisation','Super-Successful'],
    '33': ['Loves unconditionally','Humanitarian','Good preachers because they practise what they preach'],
}

export const planeTags = {
    'ACTION': {
        name: 'Action Plane',
        tags: ['Action-Oriented','Acts fast on decisions','May be impulsive','Quick to grab opportunities','Great at sports'],
    },
    'EMOTIONAL': {
        name: 'Emotional Plane',
        tags: ['Emotional','Thinks more from the heart than the head','Giving and compassionate','Golden-hearted','Supportive','Religious and/or Spiritual'],
    },
    'MENTAL': {
        name: 'Mental Plane',
        tags: ['Intelligent and intellectual','Sharp memory','Do things away from the herd'],
    },
    'PRACTICAL': {
        name: 'Practical Plane',
        tags: ['Practical','Analytical and Logical','Questions things','Difficult to argue with'],
    },
    'THOUGHT': {
        name: 'Thought Plane',
        tags: ['Good Planner','Can think big','Strong Visualisation Power','Prudent','Suited for Politics'],
    },
    'WILL': {
        name: 'Will Plane',
        tags: ['Good decision-maker','Strong Will Power','Bounce back from situations','Happy-go-lucky','Adjusting'],
    },
    'RAJYOG-I': {
        name: 'Success Plane',
        tags: ['Passionately goes after goals','Likes to enjoy riches and material gains','Bound to achieve success'],
    },
    'RAJYOG-II': {
        name: 'Earth Success Plane',
        tags: ['Patient, waits for the right opportunities','Focussed like Arjun from the Mahabharata','Property and Real Estate Avenues are profitable'],
    },
}

export const arrowTags = [
    ['Keeps balance in difficult situations'],
    ['Good at research','Academic knowledge or Spiritual Knowledge'],
    ['Great knowledge and intelligence','Spiritual and/or Religious'],
    ['Argumentative','May get into legal trouble']
]

export const loshuTags = [
    '',
    {
        field: 'Communication Skills',
        '1': ['Can communicate well','May not be able to express their inner self'],
        '2': ['Good orator','Speaks well on stage','Excellent communication skills','Can articulate ideas well','Impartial judgement'],
        '3': ['Too talkative or too introverted','May be both in different situations'],
        '4': ['Easily misunderstood by people','May get caught in a controversy unnecessarily','Super-ambitious','Not good team players'],
        '5': ['Fame and success comes with social stigma','Communication is marred with controversies','Super-ambitious','Not good team players'],
        'missing': ['Difficulty in expressing self','May depend on others to communicate','No ego','Gel well with others']
    },/*1*/
    {
        field: 'Sensitivity and Intuition',
        '1': ['Sensitive towards self and others, but cannot balance it well','Intuitive, but does not trust intuition','Empathetic','Charming'],
        '2': ['Highly intelligent','Highly intuitive','Very charming','Over-sensitive'],
        '3': ['Over-sensitive','Easily hurt','May avoid big gatherings','Very intuitive, must be careful of what they say/wish','Must be encouraged to express themselves lest they face mental health issues'],
        '4': ['Over-sensitive','Easily hurt','May avoid big gatherings','Very intuitive, must be careful of what they say/wish','Must be encouraged to express themselves lest they face mental health issues'],
        '5': ['Over-sensitive','Easily hurt','May avoid big gatherings','Very intuitive, must be careful of what they say/wish','Must be encouraged to express themselves lest they face mental health issues'],
        'missing': ['Inconsiderate and insensitive towards others','Lack intuitive abilities','Do not accept mistakes easily, instead justify them']
    },/*2*/
    {
        field: 'Creativity and Imagination',
        '1': ['Creative','Imaginative','Artistic','Knowledge-seeking','Must be careful of thinking of themselves as know-it-alls'],
        '2': ['Extremely creative','Extremely imaginative','Extremely knowledgeable','Eye for detail','Breaks social rules','May not gel well with other people','Starts things with enthusiasm but may not finish them with the same energy','Must be careful of thinking themselves as know-it-alls'],
        '3': ['Extremely knowledgeable','Suffer from a superiority complex','May not get along well with others','Can dream and think big','Starts things with enthusiasm but may not finish them with the same energy'],
        '4': ['Unrealistically imaginative','Impractical','May live in a fool’s paradise','Need support from others to translate their vision to reality'],
        '5': ['Unrealistically imaginative','Impractical','May live in a fool’s paradise','Need support from others to translate their vision to reality'],
        'missing': ['Not conventionally creative and imaginative','Must avoid artistic work','Lacks a fighting spirit','Prefers to detach from situation and surrender than fight it']
    },/*3*/
    {
        field: 'Discipline and Organisation',
        '1': ['Practical','Hard-working','Disciplined and organised','Enjoy hands-on activities'],
        '2': ['Excellent organisational skills-can create order out of chaos','Very disciplined','Materialistic','Stubbornness may cause them to be penny wise pound foolish'],
        '3': ['Over-organised and over-disciplined','Extremely stubborn','May be unaware of their own talents','Tendency to argue with people around them'],
        '4': ['Over-organised and over-disciplined','Extremely stubborn','May be unaware of their own talents','Tendency to argue with people around them'],
        '5': ['Over-organised and over-disciplined','Extremely stubborn','May be unaware of their own talents','Tendency to argue with people around them'],
        'missing': ['Lack discipline and organisation','May not be punctual','Clumsy']
    },/*4*/
    {
        field: 'Balance-Emotional, Financial and Overal',
        '1': ['Emotionally balanced','Self-accountable and self-reliable','Bounces back easily from difficult situations','Prefers smart work over hard work','Makes a great support system','Encourages and inspires others around them','May be lazy'],
        '2': ['Emotionally balanced','Self-accountable and self-reliable','Bounces back easily from difficult situations','Prefers smart work over hard work','Makes a great support system','Encourages and inspires others around them','May be lazy','Detest interference in their work','Romantic','Young-at-heart'],
        '3': ['Too frank-does not think before they speak','Prone to accidents','Cannot handle pressure/interference','Emotionally balanced','Self-accountable and self-reliable','Bounces back easily from difficult situations','Prefers smart work over hard work','Makes a great support system','Encourages and inspires others around them','May be lazy'],
        '4': ['Too frank-does not think before they speak','Prone to accidents','Cannot handle pressure/interference','Emotionally balanced','Self-accountable and self-reliable','Bounces back easily from difficult situations','Prefers smart work over hard work','Makes a great support system','Encourages and inspires others around them','May be lazy'],
        '5': ['Too frank-does not think before they speak','Prone to accidents','Cannot handle pressure/interference','Emotionally balanced','Self-accountable and self-reliable','Bounces back easily from difficult situations','Prefers smart work over hard work','Makes a great support system','Encourages and inspires others around them','May be lazy'],
        'missing': ['Lack balance in all spheres','Emotionally imbalanced']
    },/*5*/
    {
        field: 'Family bond',
        '1': ['Immense love for home and family','Can assertively express themselves to loved ones','Care for everyone','Attached to kids and may be over-protective','Insecure about losing loved ones'],
        '2': ['Immense love for home and family','Can assertively express themselves to loved ones','Worried and obsessed with the well-being of people around them','Available as a support in hard times','May interfere and try to control others’ lives'],
        '3': ['Obsessively pessimistic','Very insecure about their loved ones being hurt','Over-possessive of loved ones','Need to rationalise thoughts to avoid anxiety'],
        '4': ['Obsessively pessimistic','Very insecure about their loved ones being hurt','Over-possessive of loved ones','Need to rationalise thoughts to avoid anxiety'],
        '5': ['Obsessively pessimistic','Very insecure about their loved ones being hurt','Over-possessive of loved ones','Need to rationalise thoughts to avoid anxiety'],
        'missing': ['Poor bonding with home and loved ones','Cannot express themselves assertively','Weak romantic/marital relationship sector','May prevent flow of money, fame and name in life']
    },/*6*/
    {
        field: 'Wisdom',
        '1': ['Trusts easily (often blindly)','Thinks from the heart more than the head','Can solve intricate problems','May face betrayal','Should avoid business partnerships','May face disappointment in health/wealth/love/emotional setbacks','Great in academic fields','Eventual become spiritual or religious'],
        '2': ['Trusts easily (often blindly)','Thinks from the heart more than the head','Can solve intricate problems','May face betrayal','Should avoid business partnerships','May face disappointment in health/wealth/love/emotional setbacks','Great in academic fields','Eventual become spiritual or religious'],
        '3': ['May face major disappointments in relationships/marital sector','Wise because of their experiences','Trusts easily (often blindly)','Should avoid business partnerships','May be obsessed either with spirituality or with sexuality'],
        '4': ['Major disappointments in marital life','May be obsessed with spirituality later in life'],
        '5': ['Major disappointments in marital life','May be obsessed with spirituality later in life'],
        'missing': ['Lack of inclination towards religion/spirituality','May not be considerate towards others']
    },/*7*/
    {
        field: 'Money and Financial Management',
        '1': ['Great at financial and money management','Trusts own experience more than the words of others','Logical, analytical and scientific','Goes into the depth of things','Questions things'],
        '2': ['Doesn’t trust easily','Great at financial and money management','Logical, scientific and analytical','Stubborn, difficult to convince','May face delay in personal/professional success'],
        '3': ['Too rigid or stuck with own ideas','Doesn’t trust easily','Great at financial and money management','Logical, scientific and analytical','Extremely stubborn, difficult to convince','May face delay in personal/professional success'],
        '4': ['Too rigid or stuck with own ideas','Doesn’t trust easily','Great at financial and money management','Logical, scientific and analytical','Extremely stubborn, difficult to convince','May face delay in personal/professional success'],
        '5': ['Too rigid or stuck with own ideas','Doesn’t trust easily','Great at financial and money management','Logical, scientific and analytical','Extremely stubborn, difficult to convince','May face delay in personal/professional success'],
        'missing': ['Lack of financial management abilities','Spendthrift','Income may come, but does not stay']
    },/*8*/
    {
        field: 'Intelligence and Humanitarian Instincts',
        '1': ['Highly intelligent','Humanitarian','Helpful','Gives considerate advice','Likes being acknowledged for helping others'],
        '2': ['Highly intelligent','Humanitarian','Helpful','Gives considerate advice','Likes being acknowledged for helping others','Know and think that they are the best, may get stuck in proving themselves'],
        '3': ['May be overly-critical of others','Highly intelligent','Need to channelise their intelligence to productive tasks','Humanitarian','Helpful','Gives considerate advice','Likes being acknowledged for helping others','Know and think that they are the best, may get stuck in proving themselves'],
        '4': ['May not be aware of their own talents','Egoistic','Short-tempered','Does not enjoy mingling with others'],
        '5': ['Very aggressive','May get caught in ego battles or physical fights','May not connect well with society','Superiority complex','May also be seen as selfish'],
        'missing': ['Weak memory','Not very intellectual','May be inconsiderate towards the feelings of others']
    },/*9*/
]