import React from 'react'
import {FACEBOOK_LINK,INSTAGRAM_LINK,BLOG_LINK,UDEMY_LINK} from '../../Utils/constants';

function AboutUs() {
	return(
		<div className='mainContainer'>
			<div className=' mb4 maincontent'>
				<h2 className='underline app-heading'>About Us</h2>
				<p>We, at Ankk, are a group of Occult Healers that have been providing Numerology, Reiki and Chakra Healing Services for a long time now. We believe that the aim of all Occult Sciences is to uplift the human experience and in accordance with that, you will find that all our suggestions are extremely practical and easy-to-follow.</p>
				<p>We believe in the power of faith and shun away the fear and superstition that is usually associated with our profession. </p>
				<p>In the hope of healing the world one day,<br/>Numerologically Yours,<br/>Team Ankk</p>
			</div>
			<div className=' mb4 maincontent'>
				<h2 className='underline app-heading-3'>Contact Us</h2>
				<p>
				<span className='b'>Email:</span> numerologicallyyours@gmail.com<br/><br/>
				<span className='b' >Facebook:</span> <a className='links' href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">@numerologicallyyours</a><br/><br/>
				<span className='b'>Instagram:</span> <a className='links' href={INSTAGRAM_LINK}target="_blank" rel="noopener noreferrer">@numerologicallyyours</a><br/><br/>
				<span className='b'>Blog:</span> <a className='links' href={BLOG_LINK} target="_blank" rel="noopener noreferrer">Numerologically Yours</a>
				</p>
			</div>
			<div className='maincontent'>
				<h2 className='underline app-heading-2'>Other Products and Services</h2>
				<p><a className='links' href={UDEMY_LINK} target="_blank" rel="noopener noreferrer">Udemy Course on Healing your Heart Chakra</a></p>
			</div>
		</div>
	)
}

export default AboutUs;