import React,{useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import {fetchTrendBars} from '../../Utils/apis';
import {viewContext,appContext} from '../../App';
import YearText,{antiCombo,MonthText,DayText} from '../../InfoFiles/YearText';
import AntiYearRemedy from './AntiYearRemedy';
import StrengthBar from '../displaycomponents/StrengthBar';
import {TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500,REMEDYPATH} from '../../Utils/constants';
import './YearTrends.css';
import RemediesButton from '../remedies/RemediesButton';

function TrendBars({value}){
	if(typeof(value)==='number'){
		return (
			<div>
			<h3><span className='app-heading'>Potential:</span> {value}%</h3>
			<div className="TrendBars">
				<StrengthBar max={100} value={value}/>
			</div>
			</div>
		);
	}
	else{
		return (
			<div>
				<h3><span className='app-heading'>Education/Occult Potential:</span> {value[0]}%</h3>
				<div className="TrendBars">
					<StrengthBar max={100} value={value[0]}/>
				</div>
				<h3><span className='app-heading'>Money/Career Potential:</span> {value[1]}%</h3>
				<div className="TrendBars">
					<StrengthBar max={100} value={value[1]}/>
				</div>
			</div>
		);
	}

}

function YearInfo() {
	const {state,dispatch} = React.useContext(viewContext);
	const {appState,appDispatch} = React.useContext(appContext);
	const [yearNumber,setYearNumber] = React.useState(10);
	const [monthNumber,setMonthNumber] = React.useState(10);
	const [dayNumber,setDayNumber] = React.useState(10);
	const [isYearAnti,setYearAnti] = React.useState(false);
	const [isMonthAnti,setMonthAnti] = React.useState(false);
	const [isDayAnti,setDayAnti] = React.useState(false);
	const [year,setYear] = React.useState(new Date().getFullYear());
	const [month,setMonth] = React.useState(new Date().getMonth());
	const [day,setDay] = React.useState(new Date().getDate());
	const [remedy,setRemedy] = React.useState(false);
	const [remedyType,setType] = React.useState('');

	const yearNumberCalc = (dob) => {
		// eslint-disable-next-line
		let [y,m,d] = dob.split('-');
		m = Number(m);
		d = Number(d);
		let yearNum = (m+d+Number(year))%9;
		let monthNum = (yearNum+Number(month)+1)%9;
		let dayNum = (Number(year) + Number(month) + 1 + Number(day) + yearNum + monthNum)%9;
		let D = d%9;
		if(antiCombo.includes(yearNum.toString()+','+D.toString()))
			setYearAnti(true);
		else
			setYearAnti(false);
		if(antiCombo.includes(monthNum.toString()+','+D.toString()))
			setMonthAnti(true);
		else
			setMonthAnti(false);
		if(antiCombo.includes(dayNum.toString()+','+D.toString()))
			setDayAnti(true);
		else
			setDayAnti(false);
		setYearNumber(yearNum===0?9:yearNum);
		setMonthNumber(monthNum===0?9:monthNum);
		setDayNumber(dayNum===0?9:dayNum);
		return D===0?9:D;
	}

	const onYearChange = (event) =>{
		if(event.target.id==='year'){
			if(isNaN(Number(event.target.value))){
				NotificationManager.error('Not a year!');
				//setYear(new Date().getFullYear());
			}
			else{
				setYear(Number(event.target.value));
			}
		}
		else if(event.target.id==='month'){
			setMonth(Number(event.target.value));
		}
		else if(event.target.id==='day'){
			if(isNaN(Number(event.target.value))){
				NotificationManager.error('Not a date!');
			}
			else{
				setDay(Number(event.target.value));
			}		
		}
		setRemedy(false);
	}

	const onYearSubmit = (event) => {
		event.preventDefault();
		let bornDate = new Date(state.dob);
		let checkDate = new Date([year,Number(month+1),day].join('-'));
		if(isNaN(checkDate.getTime()))
			NotificationManager.error(`We living in ${new Date().getFullYear()}. You living in ${new Date().getFullYear()+1000}!`,'Invalid date!');
		else if(checkDate<bornDate)
			NotificationManager.warning('I don\'t think sperms have any time trend!!'); // try changing it
		else{
			if((checkDate.getFullYear()-bornDate.getFullYear())>99)
				NotificationManager.warning('Living a long life?'); //see it
			yearNumberCalc(state.dob);
		}
	}

	const onRemedyClick = (event) => {
		setRemedy(!remedy);
		setType(event.target.id);
	}

	useEffect(()=>{
		const D = yearNumberCalc(state.dob);
		if(appState.timeTrends==='')
		{
			fetchTrendBars(D)
			.then(returnData => {
				appDispatch({
					type:Object.keys(returnData)[0],
					payload:returnData,
				});
			}).catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:'ERROR'});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}
	// eslint-disable-next-line
	},[]);
	return(
		<>
			<p className='maincontent'>Knowing the trend of your life cycle is extremely useful when it comes to taking life decisions. Being aware of the potential of each year lets you plan accordingly-whether you should direct your efforts into external things like career and materialistic pursuits or focus on your own mental and physical health.<br/>To give an analogy, it is like the stock market. If you know exactly when the market is going up and when it is going down, you can invest accordingly and thus, make unlimited profits. Similarly, when you know the pattern of your life, you can invest your time, energy and money into the correct direction to make the most of its potential.</p>
			<div className='mb'>
			<div className='maincontent dateform'>
				<label htmlFor='year'>Enter info to get the trend: </label>
				<input className='inputsize' onChange={onYearChange} id='year' value={year}/>
				<select className='inputsize' defaultValue={month}  onChange={onYearChange} id="month">
  					<option value='0'>January</option>
 					<option value='1'>February</option>
 					<option value='2'>March</option>
 					<option value='3'>April</option>
 					<option value='4'>May</option>
 					<option value='5'>June</option>
 					<option value='6'>July</option>
 					<option value='7'>August</option>
 					<option value='8'>September</option>
 					<option value='9'>October</option>
 					<option value='10'>November</option>
 					<option value='11'>December</option>
				</select>
				<input className='inputsize' onChange={onYearChange} id='day' value={day}/>
				<button className='inputsize' onClick={onYearSubmit}> Find the trend </button>
			</div>
			<div className="TrendContainer">
				<div className="maincontent Trends">
					<p className='app-heading'>Personal Year {yearNumber}</p>
					<span>
					{isYearAnti?
						<>
							<p>
								{YearText[0]}
								<a id='years' href="#remedy" className='links' onClick={onRemedyClick}>click here</a>
							</p>
						</>
						:
						<>
							<p>{YearText[yearNumber]}</p>
							{appState.timeTrends==='' || yearNumber===10?null:<TrendBars value={appState.timeTrends[yearNumber-1]}/>}
						</>
					}
					</span>
				</div>
				<div className="maincontent Trends">
					<p className='app-heading'>Personal Month {monthNumber}</p>
					<span>
					{isMonthAnti?
						<>
							<p>
								{MonthText[0]}
								<a id='months' href="#remedy" className='links' onClick={onRemedyClick}>click here</a>
							</p>
						</>
						:
						<>
							<p>{monthNumber===yearNumber?MonthText[11]:MonthText[monthNumber]}</p>
							{appState.timeTrends==='' || monthNumber===10?null:<TrendBars value={appState.timeTrends[monthNumber-1]}/>}
						</>
					}
					</span>
				</div>
				<div className="maincontent Trends">
					<p className='app-heading'>Personal Day {dayNumber}</p>
					<span>
					{isDayAnti?
						<>
							<p>
								{DayText[0]}
							</p>
						</>
						:
						<>
							<p>{DayText[dayNumber]}</p>
							{appState.timeTrends==='' || dayNumber===10?null:<TrendBars value={appState.timeTrends[dayNumber-1]}/>}
						</>
					}
					</span>
				</div>
			</div>
			</div>
			<a name='remedy'></a>
			{remedy?<AntiYearRemedy type={remedyType}/>:null}
			<div className="maincontent get-remedy-section">
				<span>To get a complete prediction of your 9-year cycles, get personalised reading </span>
				<RemediesButton buttonText={'Get Personalised Reading!'} />
				<span>Note: Personal Years begin every year on the 5th of February.</span>
			</div> 
		</>
	);
}

export default YearInfo;