import React from 'react';
import './Components.css';

function StrengthBar({max,value,colorGradient=['red','orange','yellow','#00cc00','lime']}){
	let color, relValue;
	relValue = value*100/max;
	let ultra ='';
	if (relValue>100){
		relValue=100;
		ultra='Ultra';
	}

	if(relValue>80)
		color=colorGradient[4];
	else if(relValue>60)
		color=colorGradient[3];
	else if(relValue>40)
		color=colorGradient[2];
	else if(relValue>20)
		color=colorGradient[1];
	else
		color=colorGradient[0];
	const style = {
		backgroundColor:color,
		width:`${relValue}%`
	}

	return(
			<div className={'StrengthBar'+ultra}>
				<p className='StrengthValue' style={style}></p>
			</div>
	)
}

export default StrengthBar;