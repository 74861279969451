import React from 'react';
import { SiUdemy } from "react-icons/si";
import { FaBloggerB,FaFacebookF,FaInstagram } from "react-icons/fa";
import {FACEBOOK_LINK,INSTAGRAM_LINK,BLOG_LINK,UDEMY_LINK,PRIVACYPOLICY} from '../../Utils/constants';
import './Footer.css';

function Footer(){
	return(
		<>
			<span>Property of Puchiman 2020.</span>	
			<a href={PRIVACYPOLICY} target="_blank" rel="noopener noreferrer" style={{'marginLeft': '5px'}}>Privacy Policy</a>
			<div className='sociallinks'>
				<a href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer"><FaFacebookF size="20px"/></a>
				<a href={INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer"><FaInstagram size="20px"/></a>
				<a href={BLOG_LINK} target="_blank" rel="noopener noreferrer"><FaBloggerB size="20px" /></a>
				<a href={UDEMY_LINK} target="_blank" rel="noopener noreferrer"><SiUdemy size="20px"/></a>
			</div>
		</>
	);
}

export default Footer;