//import {FRIEND,NONFRIEND,NEUTRAL,TEMPFRIEND} from '../constants';

export const loshuBased = [
	'Yes! Settling abroad, in a land other than your native land, provides a good opportunity for you. Make sure that the country you choose is compatible with you.', // 6 but 5
	'Definitely yes! Settling abroad, in a land other than your native land, provides much better opportunities for you than your home country. Make sure that the country you choose is compatible with you.', // 6 and 5
	'You may try your luck at settling abroad. Whether in your home country, or abroad, you will have ample opportunities for success. Make sure that the country you choose is compatible with you.', // 5 but 6
	'Definitely not. Your native land provides much better opportunities for you. Settling in a foreign country predisposes you to immense struggle there.', //none
]

export const dBased = ['',
	'For example, Canada, Netherlands, Australia, USA are good countries for you to settle in. UK, on the other hand, is not compatible with you.',//1
	'For example, USA is a good country for you to settle in. UK, on the other hand, is not compatible with you.',//2
	'For example, USA is a good country for you to settle in. Canada and Netherlands, on the other hand, are not compatible with you.',//3
	'For example, Canada, Netherlands, USA are good countries for you to settle in. UK, on the other hand, is not compatible with you for permanent settlement.',//4
	'For example, Canada, Netherlands, Australia, USA are good countries for you to settle in.',//5
	'For example, Canada, Netherlands, USA are good countries for you to settle in.',//6
	'For example, Canada, Netherlands, USA are good countries for you to settle in.',//7
	'For example, Canada, and Netherlands, are good countries for you to settle in. USA, on the other hand, is not compatible with you.',//8
	'For example, Canada, Netherlands, USA are good countries for you to settle in. Australia, on the other hand, is not compatible with you.',//9
]

export const compatibilityBased = {
	'1': 'This country is extremely compatible with you. Settling here offers many opportunities for success!',
	'-1': 'This country is not compatible with you. Settling here predisposes you to struggle and other disappointments in life.',
	'0': ' You may try your luck in this country because it poses no obstacles to your success.',
	'2': {
		'4':'The country provides good opportunities for you but only if you visit it temporarily-for education, temporary employment opportunities or the like. Permanently settling in this country can prove detrimental for you.',
		'8':'The country provides good opportunities for you but only if you visit it temporarily-for education, temporary employment opportunities or the like. Permanently settling in this country can prove detrimental for you.',
		'3':'This country is compatible with you as far as educational opportunities are concerned-if you visit this country to study or to pursue professions that are related to research, education, teaching, healing or occult, this proves to be very profitable.',
	},
}