import React from 'react';
import { HiUserCircle } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { viewContext } from '../../App';
import { LOGINPATH } from '../../Utils/constants';
import {signOut} from '../../Utils/firebaseUtils/loginUtils';
import "./Header.css";

function UserMenu ({number}){
	const [isOpen,setOpen] = React.useState(false);
	const {state} = React.useContext(viewContext);

	const onMenu = (event) =>{
		setOpen(!isOpen);
	}

	const handleLogout = () =>{
		setOpen(!isOpen);
		signOut();
	}

	return(
		<div className="user-header">
			<div className="user-icon">
				<HiUserCircle size="40px" onClick={onMenu}/>
			</div>
			{isOpen?
				<div className='user-menu'>
					{state.login?
						<>
							<div>{(state.fname?state.fname+' ':'') + (state.mname?state.mname +' ':'') + (state.lname?state.lname +' ':'')}</div>
							<div>{number}</div>
							<hr className="w-90"/>
							<div onClick={handleLogout} className='logout-button'>Log Out</div>
						</>
						:
						<>
							<div>Guest User</div>
							<hr className="w-90"/>
							<div onClick={onMenu}><Link to={LOGINPATH} style={{"textDecoration":"none"}}>Log In</Link></div>
						</>
					}
				</div>
				:null
			}
		</div>
	)
}

export default UserMenu;