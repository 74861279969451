//view(view/pages of the app) constants
export const DOB_PREDICTION = "DOB_PREDICTION"; 
export const HOME = "HOME";
export const DOB_DATA = "DOB_DATA"; //when data has been added via the data form
export const DOB = /DOB_/; //for checking if we have passed the data gathering step
export const FORM = "FORM"; //for displaying form


//error constant
export const ERROR = "ERROR";
export const DB_ERROR = "DB_ERROR";

//sex
export const OTHER = "other";
export const MALE = "male";
export const FEMALE = "female";

//type of D-C combination
export const ANTI = 1;
export const OPPOSITE = 2;
export const BEST = 3;
export const PROFESSION = 0;

//compatibility types
export const FRIEND = 1;
export const NONFRIEND = -1;
export const NEUTRAL = 0;
export const TEMPFRIEND = 2;

//PATHS
export const HOMEPATH = '/aboutyou';
export const PROTECTEDPATH = '/user';
export const PROFESSIONSPATH = '/user/professions';
export const LUCKYPATH = '/user/luckynumbers';
export const NEWPATH = '/new'; // temp path for functionality not set
export const PARTNERPATH = '/user/partnercompatibility';
export const NAMEPATH = '/user/namecalculator';
export const FOREIGNPATH = '/user/foreignsettlement';
export const TRENDPATH = '/user/yeartrends';
export const CELEBPATH = '/celebrities';
export const ABOUTPATH = '/aboutus';
export const REMEDYPATH = '/remedies';
export const DEALPEOPLEPATH = '/user/deal_with_people'
export const HELPUSPATH = '/helpus';
export const TESTIMONIALPATH = '/testimonials';
export const FAQPATH = '/faqs';
export const DASHBOARDPATH = '/dashboard';
export const LOGINPATH = '/user/dashboard';
export const PRIVACYPOLICY = '/privacy';
//dob type requests
export const BASIC = 'basic';

//error messages
export const TITLE_400 = 'INVALID DATA';
export const MESSAGE_400 = 'Data entered is not recognized by the system. Please check the data and try again';
export const TITLE_500 = 'OOOPS!!!';
export const MESSAGE_500 = 'It seems there is a network issue. Check your internet connection or try again after sometime';

//social medial links
export const FACEBOOK_LINK = "https://www.facebook.com/numerologicallyyours";
export const INSTAGRAM_LINK = "https://instagram.com/numerologicallyyours?igshid=1gtyw4owtcx7p";
export const BLOG_LINK = "https://numerologicallyyours.blogspot.com/2020/07/why-name-numerology-works.html";
export const UDEMY_LINK = "https://www.udemy.com/course/healing-your-heart-chakra/?referralCode=2B5ED6324867BA53CF6D";
export const FORM_LINK = "https://forms.gle/FiHeN41jgekaSe3E6";//not in use anymore
export const REMEDY_PAYMENT_PAGE = "https://forms.gle/JT4VPhaPnhyxgTu19"
export const DONATION_LINK = "https://forms.gle/FJdgi5wo18T3e8Jx8";
export const SURVEY_LINK = "https://forms.gle/qLHXbAcbgcjs54D89";

//login methods
export const PHONE = 'phone';

//colllection names
export const USERS = 'users';