import React from 'react';
import {NavLink} from 'react-router-dom';
import {
	HOMEPATH,
	NEWPATH,
	PROFESSIONSPATH,
	LUCKYPATH,
	PARTNERPATH,
	NAMEPATH,
	FOREIGNPATH,
	TRENDPATH,
	REMEDY_PAYMENT_PAGE,
	REMEDYPATH,
} from '../../Utils/constants';
import './Header.css';



function QuickLinks(){
	return(
		<nav className="Quickbar">
			<NavLink className="Quickitems" to={HOMEPATH}>About You</NavLink>
			<NavLink className="Quickitems" to={PROFESSIONSPATH}>Professions</NavLink>
			<NavLink className="Quickitems" to={LUCKYPATH}>Lucky Numbers</NavLink>
			<NavLink className="Quickitems" to={PARTNERPATH}>Partner Compatibility</NavLink>
			<NavLink className="Quickitems" to={NAMEPATH}>Name Analysis</NavLink>
			<NavLink className="Quickitems" to={FOREIGNPATH}>Foreign Settlement</NavLink>
			<NavLink className="Quickitems" to={TRENDPATH}>Life Trend</NavLink>
			<a href={REMEDY_PAYMENT_PAGE} target='_blank'  rel="noopener noreferrer" className="Quickitems">Remedies</a>
		</nav>
	);
}

export default QuickLinks;