import React from 'react';
import {REMEDY_PAYMENT_PAGE} from '../../Utils/constants';
import './Remedies.css';

function RemediesButton ({buttonText}) {
    return(
        <div className='remedies-button-container'>
          <button className='remedies-button'><a className='remedies-button-link' href={REMEDY_PAYMENT_PAGE} target='_blank'  rel="noopener noreferrer">{buttonText}</a></button>  
        </div>
    )
}

export default RemediesButton;