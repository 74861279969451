import React from 'react';
import { HiMenu,HiX } from "react-icons/hi";
import {NotificationManager} from 'react-notifications';
import { viewContext } from "../../App";
import Navigation from "./Navigation.js";
import logo from "../../Assets/Ankk_logo.png";
import './Header.css';
import { HOME } from '../../Utils/constants';

function NavMenu (){
	const [isOpen,setOpen] = React.useState(false);

	const onMenuOpen = (event) =>{
		setOpen(true);
	}

	const onMenuClose = (event) =>{
		setOpen(false);
	}

	return(
		<div className="NavHeader">
			<div className="MenuIcon">
				{isOpen?<HiX onClick={onMenuClose} size="40px"/>:<HiMenu onClick={onMenuOpen} size="40px"/>}
			</div>
			<img src={logo} alt={'Ankk'} className="Ankk"/>
			<h1 className="AnkkName">Ankk</h1>
			{isOpen?<Navigation close={onMenuClose}/>:null}
		</div>
	)
}
export default NavMenu;