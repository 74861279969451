import {db} from '../firebase';
 
export const getData = async(index,collectionName) => {
    try{
        const doc = await db.collection(collectionName).doc(index).get();
        if(doc.exists)
            return doc.data();
        else{
            return null;
        }
    }catch(error){
        throw error;
    }
}

export const setData = async(index,collectionName,data) => {
    try{
        await db.collection(collectionName).doc(index).set(data);
    }catch(error){
        throw error;
    }
} 