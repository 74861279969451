import React from 'react';
import {Link} from 'react-router-dom';
import {REMEDYPATH,TRENDPATH,UDEMY_LINK} from '../Utils/constants';
import RemediesButton from '../Components/remedies/RemediesButton';

export const questions = [
    'What does the Name Calculator do?', //name numerology
    'Why should I change my Name?',
    'Will I have to change my name on Official Documents?',
    'How much time does it take for the Name Change to take Effect?', 
    'How does Numerology help in Foreign Settlement?', //foriegn setllement
    'I found a country which is good temporarily. What does that mean?',
    'What does compatibility with a country mean? How does compatibility impact a person? ',
] 

export const answers = [
    <>
        Every alphabet of your name has a corresponding numerical value and frequency. Based on the Cheiro Method, the value of your total name is calculated.
        <RemediesButton buttonText={'Get Your Name Corrected!'}/>
    </>,
    <>
        The frequency of your name dominates your entire life. From the time that you were born to the point of your life up until now, you have been recognised by a certain name. Your name is your identity- Loved ones call you by your name, they address you by your name, in fact, even when you meet someone new in life, the name is the first thing that you use to introduce yourself. As such, your name has immense potential to impact your success. It can propel your life forward or hold you back from dashing towards success.<br/> 
        Numerologically speaking, your name must be compatible with your Driver and Conductor numbers. Your life is like a chariot, and these numbers are like the horses pulling that chariot forward. Your name represents the reigns that controls these horses-if it is incompatible, it can lead the chariot astray but if compatible, it can drive it forward to achieve the highest level of success.<br/>
        To know more, <a href={UDEMY_LINK} target="_blank" rel="noopener noreferrer" className='links'>click here</a>.
        <RemediesButton buttonText={'Get Your Name Corrected!'}/>
    </>,
    <>
        Absolutely not! NO CHANGE required on Pan Cards, Aadhaar Cards, Birth Certificates or any other Official Documents that you use. The name correction we suggest will NOT require you to run around government offices or go through any paperwork.<br/> 
        We guarantee a simple process which is not time-consuming AT ALL.
        <RemediesButton buttonText={'Get Your Name Corrected!'}/>
    </>,
    <>
        The change in your life begins as soon as you change the frequency of your name. It begins in a subtle way and can be seen tangibly in life based on a variety of factors. Most importantly, it also depends on the energy of the year, month and day (To check your personal year, month and day, <Link to={TRENDPATH} className='links'>click here</Link>).<br/>
        In the year meant for professional success for you, it can spearhead you to get that promotion or pay-rise. In the year meant for financial stability, it can bring balance to your finances almost immediately. And in the year meant for major changes like settling abroad, it can boost your chances and make the transition smoother.<br/>
        Nevertheless, the change in energy begins as soon as your name change is implemented-through an attitude change, a thought change and a paradigm shift!
    </>,
    <>
        If you are looking to settle abroad, then there are two things that Numerology can do for you:<br/> 
        1) It can tell you how compatible the country is with you.<br/>
        2) It can tell you which year and month is best suited to make this change.
    </>,
    <>
        This means that the country offers good opportunities as far as the following are concerned:<br/>
        {'>'} Educational Opportunities/Visiting the Country for some sort of Higher Studies.<br/>
        {'>'} Visiting the Country as a tourist to explore or for recreational purposes.<br/>
        {'>'} Staying there for a short-term professional project.<br/><br/>
        Basically, visiting the country for a short period of time and not permanently settling there.<br/>
    </>,
    <>
        A compatible country is more likely to provide you with more opportunities, easy settlement and greater success as compared to a country which is anti or opposite to your frequency. In the latter, you may find yourself facing many difficulties in finding work.<br/>
        In countries that are opposite to your frequency, the intial phase may be troublesome but things may settle over a period of time.<br/>
        However, in countries which are anti to your frequency, the problems are larger and appear sooner or later. These problems are usually chronic in nature and in some cases, can make your life upside down. They are likely to disturb you for a long time or make your life upside down.<br/> 
        To know more about this and discuss examples where we have found clients facing trouble abroad, you can contact us. Schedule a call with us at a time that is convenient for you. 
        <RemediesButton buttonText={'Schedule a Personal Session!'}/>
    </>,
]