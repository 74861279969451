import {HOME,DOB_PREDICTION,ERROR,DOB_DATA,FORM,DB_ERROR} from '../Utils/constants';

export const viewReducer = (state,action) => {
	let newState;
	switch(action.type){
		case DOB_DATA:
			newState = {...state,...action.payload};
			return{
				...newState,
				view: DOB_DATA
			};
		case DOB_PREDICTION:
			newState = {...state,...action.payload};
			return{
				...newState,
				view: DOB_PREDICTION
			};
		case FORM:
			return{
				...state,
				...{
					view: FORM,
					login: true
				}
			}
		case ERROR:
			return{
				...state,
				view: FORM,
			};
		case DB_ERROR:
			return{
				...state,
				...{
					view: HOME,
					login: true
				}
			};
		case 'clear':
			return action.payload;
		default:
			return state;	
	}
};

export const appReducer = (state,action) => {
	return {...state,...action.payload};
};

