import React,{useEffect} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {FaRegArrowAltCircleLeft,FaRegArrowAltCircleRight} from 'react-icons/fa';
import { viewContext,appContext } from "../../App";
import {NotificationManager} from 'react-notifications';
import { ANTI,OPPOSITE,BEST,DASHBOARDPATH } from "../../Utils/constants";
import {fetchDobTraits} from "../../Utils/apis";
import LoshuGrid from "../displaycomponents/LoshuGrid";
import {TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500,REMEDYPATH} from '../../Utils/constants';
import './DobTraits.css';
import RemediesButton from '../remedies/RemediesButton';
const typeSpeed = 200; // for type writer effect
let position = 0; //position for typewriter effect

const numberRemedyText = {
	'delay':'Such a delay can be stabilised, especially through the Number of Balance. Remedies for the same can be found',
	'extreme':'Such an extreme can be stabilised, especially through the Number of Balance. Remedies for the same can be found'
}

const numberHeading = ['',
	'Communication',
	'Sensitivity and Intuition',
	'Creativity and Imagination',
	'Discipline and Organisation',
	'Balance',
	'Relationships and Luxury',
	'Wisdom and Spirituality',
	'Money and Financial Management',
	'Intelligence and Humanitarian Values'
]

function Intro({state,setCurrent}){
	const [grid,setGrid] = React.useState([]);
	const [D,setD] = React.useState("");
	const [C,setC] = React.useState("");

	useEffect(()=>{
		let mounted=true;

		if(D === "")
		{	
			setTimeout(()=>{setD("Driver");},typeSpeed);
			const tempGrid = state.date.split("").concat(state.month.split("").concat(state.year.split("")));
			if(mounted)
				setGrid(tempGrid);
			position=0;
		}
		else if(D === "Driver")
		{
			setTimeout(()=>{if(mounted){setD(D + " = ");}},typeSpeed);
		}
		else if(C === "" && position < 2)
		{
			if(position === 1)
			{				
				setTimeout(()=>{
					if(mounted)
						setD(D + grid[position] + " = ");
					++position;
				},typeSpeed);
			}
			else
			{
				setTimeout(()=>{
					if(mounted)
						setD(D + grid[position] + " + ");
					++position;
				},typeSpeed);
			}
		}
		else if(C === "" && position === 2)
		{
			setTimeout(()=>{
				if(mounted)
					setD(D + state.D);
				++position;
			},typeSpeed);
		}
		else if(C === "" && position > 2)
		{
			setTimeout(()=>{
				if(mounted)
					setC("Conductor");
				position = 0;
			},typeSpeed);
		} 
		else if (C === "Conductor")
		{
			setTimeout(()=>{
				if(mounted)
					setC(C + " = ");
			},typeSpeed);
		}
		else if(C !== "" && position<grid.length)
		{
			if(position === grid.length-1)
			{
				setTimeout(()=>{
					if(mounted)
						setC(C + grid[position] + " = ");
					++position;
				},typeSpeed);
			}
			else
			{			
				setTimeout(()=>{
					if(mounted)
						setC(C + grid[position] + " + ");
					++position;
				},typeSpeed);
			}
		}
		else if(C !== "" && position === grid.length)
		{
			setTimeout(()=>{
				if(mounted)
					setC(C + state.C);
				++position;
			},typeSpeed);
		}
		else if(C !== "" && position > grid.length)
		{
			setTimeout(()=>{
				if(mounted)
					setCurrent(1);

			},typeSpeed);
		} 

		return () => mounted = false;
	},[D,C,grid,state]);
	return(
		<>
			<div className="introtext">{D}</div>
			<div className="introtext">{C}</div>
		</>
	);
}

function NumberTraits({state}){
	return(
		<>
			<h2 className='underline app-heading-1'>Pesonality Traits</h2>
			<h3 className='app-heading'>Characteristics based on numbers present in the grid</h3>
			<ul>
				{
					state.repititiveTraits.map((trait)=>{
						return(<li>
							<p >{trait[0]}</p>
							{trait.length>1?<p>{numberRemedyText[trait[1]]} <NavLink className='links' to={REMEDYPATH}>here</NavLink></p>:null}
						</li>)	
					})
				}
			</ul>
			<h3 className='app-heading'>Characteristics based on numbers absent in the grid</h3>
			<ul>
				{	
					state.missingTraits[0].map((trait,index)=>{
						return(
							<li key={index}>
								<span className='b app-heading'>{numberHeading[state.missingTraits[1][index]]}</span>
								<span>{trait.map((t,i)=><p key={i}>{t}</p>)}</span>
							</li>
						)
					})
				}
				{
					Number(state.loshuGrid[5-1])===0?null:<p>The above-mentioned tendencies, however, may not make themselves so apparent because of the presence of the Number of Balance which compensates for them to a certain extent.</p>
				}
			</ul>
			{state.dandcType===ANTI || state.dandcType===OPPOSITE?<p>{state.dandcCombination}</p>:null}
			<p>For each aspect mentioned above, there are easy-to-do remedies that can help you access the missing trait or tendency which you may wish to have. Even in cases where there are other numbers that compensate for a certain lack to a particular extent, doing these remedies can help completely make up for it.</p>
			<RemediesButton buttonText='Checkout Remedies'/>
			<p>Checkout our other services like compatible profession, lucky numbers and lucky colors, compatibility with your partner (personal/professional) and much more by clicking the menu.</p>
		</>
	);
}

function PlaneTraits({planeTraits}){
	return(
		<ul>
			{planeTraits.map((traits,i) => {
					if(traits.includes('-100') || traits.includes('-67'))
						return <li key={i}><p><span className='b app-heading'>{traits.slice(traits.indexOf('The'),traits.indexOf('Plane')+5)}</span>{traits.slice(traits.indexOf('Plane')+5)}</p></li>;
					else
						return <li key={i}><p>{traits}</p></li>;
				})
			}
		</ul>
	)
}

function DriverRelatedInfo({state}){
	return(
		<>
			<p><span className='b app-heading'>Driver:</span> {state.driverTraits[0]}</p>
			<p><span className='b app-heading'>Conductor:</span> {state.driverTraits[1]}</p>
			{state.dandcType===BEST?<p>{state.dandcCombination}</p>:null}
			{state.driverTraits.slice(2,).map((trait,i) => <p key={i}>{trait}</p>)}
		</>
	)
}

function DobTraits(){
	const {state,dispatch} = React.useContext(viewContext);
	const {appState,appDispatch} = React.useContext(appContext);
	const [current, setCurrent] = React.useState(0);
	const [display,setDisplay] = React.useState(1);
	const [nextArrow,setNext] = React.useState(true);

	const onNext = () =>{
		if(display===1)
			setCurrent(2);
		if(display<3)
			setDisplay(display+1);
		window.scrollTo(0, 0);
		if(display===3){
			setNext(false);
			/*dispatch({
				type:'bar',
				value: true,
			});*/
		}

	}

	const onPrevious = () =>{
		if(display>1)
			setDisplay(display-1);
		if(display===2)
			setCurrent(1);
		window.scrollTo(0, 0);
		setNext(true);
	}

	useEffect(()=>{
		if(appState.dobTraits==='')
		{
			fetchDobTraits(state)
			.then(returnData => {
				appDispatch({
					type:Object.keys(returnData)[0],
					payload:returnData,
				});
			}).catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:'ERROR'});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}
	});
	
	return(
		<>
			{appState.dobTraits===''?null:
				<div>
					<a name="top"></a>
					{current<2?<Intro state={appState.dobTraits} setCurrent={setCurrent}/>:null}
					{current!==0 && display>1?
						<div className='loshucontainer'>
							<LoshuGrid loshuGrid={appState.dobTraits.loshuGrid} D={appState.dobTraits.D} C={appState.dobTraits.C} K={appState.dobTraits.K}/>
						</div>
						:null
					}
					{current===0?null:
						<>
							<div className="maincontent">
								{display===1?<DriverRelatedInfo state={appState.dobTraits}/>:null}
								{display===2?<PlaneTraits planeTraits={appState.dobTraits.planeTraits}/>:null}
								{display===3?<NumberTraits state={appState.dobTraits}/>:null}
							</div>
							<a href="#top" className="slidebuttons">
								{display===1?null:<FaRegArrowAltCircleLeft onClick={onPrevious} size='40px'/>}
								{nextArrow?<FaRegArrowAltCircleRight onClick={onNext} size='40px'/>:<Redirect to={DASHBOARDPATH}/>}
							</a>
						</>
					}
				</div>
			}
		</>
	);
}

export default DobTraits;
