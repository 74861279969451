import React from 'react';
import './OurInfo.css';

const testimonialUrl = process.env.PUBLIC_URL+'/testimonials/';

function Testimonial(){
	const arr = [];
	for(let i=1;i<=25;++i)
	{
		arr.push(<img src={testimonialUrl+i+'.jpg'} alt='testimonial' key={i}/>);
	}
	const arri=[];
	for(let i=26;i<=30;++i)
	{
		arri.push(<img src={testimonialUrl+i+'.jpg'} alt='testimonial' key={i}/>);
	}
	return(
		<div className='container'>
			<h1 className='tc'>Hear from our clients!</h1>
			<div className='imgcontainer img1'>
				{arr}
			</div>
			<div className='imgcontainer img2'>
				{arri}
			</div>
		</div>
	);
}

export default Testimonial;