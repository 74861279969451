import firebase from 'firebase';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN, 
    databaseURL: process.env.REACT_APP_DATABASE_URL, 
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (typeof window != 'undefned' && !firebase.apps.length){
    firebase.initializeApp(firebaseConfig);
    
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const analytics = firebase.analytics();


export default firebase;

