import React from 'react';
import { viewContext } from "../../App";
import DobForm from '../displaycomponents/DobForm';
import YearInfo from './YearInfo';
import {DOB,HOME} from "../../Utils/constants.js";

function YearTrends (){
	const {state} = React.useContext(viewContext);
	let currentComponent;
	if(DOB.test(state.view))
		currentComponent = <div className= 'mainContainer'> <YearInfo/> </div>;
	else if(state.view===HOME)
		currentComponent = <div className='mainContainer'> </div>
	else
		currentComponent = <DobForm/>; 
	return(
		<>
			{currentComponent}
		</>
	);
}

export default YearTrends;