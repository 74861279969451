import React,{useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {fetchNameCompatibility} from '../../Utils/apis';
import {viewContext,appContext} from '../../App';
import {NONFRIEND,TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500,BLOG_LINK,FORM_LINK} from '../../Utils/constants';
import RemediesButton from '../remedies/RemediesButton';

function NameInfo() {
	const {state,dispatch} = React.useContext(viewContext);
	const {appState,appDispatch} = React.useContext(appContext);

	useEffect(()=>{
		if(appState.nameCompatibility===''){
			fetchNameCompatibility(state)
			.then(returnData => {
				appDispatch({
					type:Object.keys(returnData)[0],
					payload:returnData,
				});
			}).catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:'ERROR'});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}
	},[]);
	return(
		<div className='maincontent'>
			<p>The following calculator uses Cheiro values of letters.To know why name spelling correction works, <a className='links' href={BLOG_LINK} target='_blank'  rel="noopener noreferrer">click here.</a></p>
			{appState.nameCompatibility===''?null:
				<div>
					<p>Given your name, your name spelling come to a total of <span className='app-heading'>{appState.nameCompatibility.nameNumber} </span> .</p>
					<p>Given your date of birth, ideally your name spelling should come to a total of <span className='app-heading'>{appState.nameCompatibility.idealNameNumber.toString()} </span>.</p>
						{
							appState.nameCompatibility.nameDComp===NONFRIEND?
							<p className='app-heading'>Your name number is not compatible with your driver.</p>:null
						}
						{
							appState.nameCompatibility.nameCComp===NONFRIEND?
							<p className='app-heading'>Your name number is not compatible with your conductor.</p>:null
						}
						{
							Number(appState.nameCompatibility.nameNumber)===4 || Number(appState.nameCompatibility.nameNumber)===8?
							<p className='app-heading'>Your name number is one that is associated with struggle and is likely to be an obstacle in your growth.</p>:null
						}
						{
							appState.nameCompatibility.nameOriginalComp===NONFRIEND?
							<p className='app-heading'>Your name number comes from a combination of two numbers which are not compatible with each other.</p>:null
						}
						{
							appState.nameCompatibility.firstDComp===NONFRIEND?
							<p className='app-heading'>The name number of your first name is not compatible with your driver.</p>:null
						}
						{
							appState.nameCompatibility.firstCComp===NONFRIEND?
							<p className='app-heading'>The name number of your first name is not compatible with your conductor.</p>:null
						}
						{
							Number(appState.nameCompatibility.firstNameNumber)===4 || Number(appState.nameCompatibility.firstNameNumber)===8?
							<p className='app-heading'>The name number of your first name is one that is associated with struggle and is likely to be an obstacle in your growth.</p>:null
						}


				</div>
			}
			<p>Please note that this is a computer-generated result. To give precise suggestions for name change for yourself or for your business, we will require a little more information from you in person.</p> 
			<p>For an accurate and personalised reading, get in touch with us by.</p>
			<RemediesButton buttonText={'Get Your Name Corrected!'}/>
		</div>
	);
}

export default NameInfo;