import React from 'react';

function AntiYearRemedy({type}){
	return(
		<div className='maincontent mb'>
			<h3 className='app-heading'>Remedy</h3>
			<ol>
				<li>Visit any surgical hospital in your locality and sit at the reception for 5 to 10 minutes. Before leaving, drink a glass of water from the water cooler or dispenser kept there or from the hospital canteen.</li>
				<li>Prick the middle finger of your right hand with a small (sterilised) needle and allow a few drops of blood to fall from it. Do this at least once a month.</li>
			</ol>
			<p>With anti-{type}, there is a possibility that accidents or physical health issues may lead to a situation where you may have to visit the hospital or suffer from blood loss. By doing the above-mentioned remedies, you have anticipated the impact of those events and in turn, reduced the actual impact. Naturally, this reduces any mental or physical suffering.</p>
		</div>
	);
}

export default AntiYearRemedy;