import React from 'react';
import "./Components.css"
function Loshugrid ({loshuGrid,D,C,K}){
	return(	
		<table className="LoshuGrid">
			<tbody>
				<tr className="">
					<td className="lc ulc LoshuCell">{Array(loshuGrid[4-1]).fill(4,0,(loshuGrid[4-1]+1)).join(" ")}</td>
					<td className="lc LoshuCell">{Array(loshuGrid[9-1]).fill(9,0,(loshuGrid[9-1]+1)).join(" ")}</td>
					<td className="lc urc LoshuCell">{Array(loshuGrid[2-1]).fill(2,0,(loshuGrid[2-1]+1)).join(" ")}</td>
					<td className="ec LoshuCell">D: {D}</td>
				</tr>
				<tr className="">
					<td className="lc LoshuCell">{Array(loshuGrid[3-1]).fill(3,0,(loshuGrid[3-1]+1)).join(" ")}</td>
					<td className="lc LoshuCell">{Array(loshuGrid[5-1]).fill(5,0,(loshuGrid[5-1]+1)).join(" ")}</td>
					<td className="lc LoshuCell" >{Array(loshuGrid[7-1]).fill(7,0,(loshuGrid[7-1]+1)).join(" ")}</td>
					<td className="ec LoshuCell">C: {C}</td>
				</tr>
				<tr className="">
					<td className="lc llc LoshuCell">{Array(loshuGrid[8-1]).fill(8,0,(loshuGrid[8-1]+1)).join(" ")}</td>
					<td className="lc LoshuCell">{Array(loshuGrid[1-1]).fill(1,0,(loshuGrid[1-1]+1)).join(" ")}</td>
					<td className="lc lrc LoshuCell">{Array(loshuGrid[6-1]).fill(6,0,(loshuGrid[6-1]+1)).join(" ")}</td>
					<td className="ec LoshuCell">K: {K}</td>
				</tr>
			</tbody>
		</table>	
	);
}

export default Loshugrid;