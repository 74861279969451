import React,{useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {fetchProfessions} from '../../Utils/apis';
import {viewContext,appContext} from '../../App';
import {TITLE_400,TITLE_500,MESSAGE_400,MESSAGE_500} from '../../Utils/constants';
function List({items}) {
	let list = items.map(i => <li>{i}</li>);
	return(
		<ul className='app-size'>
			{list}
		</ul>
	)
}

function ProfessionsInfo() {
	const {state,dispatch} = React.useContext(viewContext);
	const {appState,appDispatch} = React.useContext(appContext);

	useEffect(()=>{
		if(appState.professions==='')
		{
			fetchProfessions(state)
			.then(returnData => {
				appDispatch({
					type:Object.keys(returnData)[0],
					payload:returnData,
				});
			}).catch(error => {
				if(error===400){
					NotificationManager.error(MESSAGE_400,TITLE_400);
					dispatch({type:'ERROR'});
				}
				else{
					NotificationManager.info(MESSAGE_500,TITLE_500);
				}
			})
		}
		// eslint-disable-next-line
	},[]);

	return(
		<>
			{appState.professions===''?null:
				<div className='maincontent'>
					{appState.professions.driverProf.length===0?null:
						<>
							<p>Based on your driver number, the following are successful professional avenues for you: </p>
							<List items={appState.professions.driverProf}/>
						</>
					}
					{appState.professions.dcProf.length===0?null:
						<p>{appState.professions.dcProf}</p>
					}
					<p>{appState.professions.driverProf.length===0?'Professions':'Other professions'} that are compatible with you are: </p>
					<List items={appState.professions.planesProf.concat(appState.professions.numbersProf)}/>
					<p className='app-heading'>Note: This is not an exhaustive list. If your current profession is not listed here and you want to check compatibility with it, contact us.</p>
				</div>
			}
		</>
	);
}

export default ProfessionsInfo;